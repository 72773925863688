export enum BLOCK_TYPE {
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
  SLIDE = 'SLIDE',
  VIDEO = 'VIDEO',
  TAB = 'TAB',
  PRODUCT_LIST = 'PRODUCT_LIST',
  VOUCHER = 'VOUCHER',
}

export enum PREVIEW_DEVICE {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}

export enum TAB_MEDIA {
  MEDIA1 = 'MEDIA1',
  MEDIA2 = 'MEDIA2',
}

export enum PRODUCT_LIST_FILTER {
  CAMPAIGN = 'CAMPAIGN',
  KEYWORD = 'KEYWORD',
  CATEGORY_TREE = 'CATEGORY_TREE',
  RATING = 'RATING',
  DROPDOWN = 'DROPDOWN',
  AGENCY = 'AGENCY',
  YYYYMMDD = 'YYYYMMDD',
  RANGE = 'RANGE',
  NUMBER = 'NUMBER',
}

export enum TAB_EFFECT_IMAGE {
  CUSTOM = 'CUSTOM',
  ANIMATE = 'ANIMATE',
}

export enum FILTER_TAB_ENUM {
  FILTERS = 'FILTERS',
  SPECIFIC_PRODUCTS = 'SPECIFIC_PRODUCTS',
}

export enum MODULE_ENUM {
  SHOP = 'SHOP',
  RESTAURANT = 'RESTAURANT',
  HOTEL = 'HOTEL',
  TRAVEL_TOUR = 'TRAVEL_TOUR',
  CAR = 'CAR',
  FLIGHT = 'FLIGHT',
}

export enum MARKETING_STATUS_ENUM {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  PAUSED = 'PAUSED',
  IN_ACTIVE = 'INACTIVE',
}
