<div
  class="h-full"
  *ngIf="product?.id; else productDefaultTemplate"
  [ngSwitch]="module"
>
  <!-- HOTEL -->
  <div
    *ngSwitchCase="'HOTEL'"
    class="max-w-sm flex flex-col h-full bg-white rounded-lg border border-gray-200"
  >
    <div class="relative p-2">
      <img
        class="rounded-lg w-full object-cover"
        style="aspect-ratio: 264 / 148.5; object-fit: cover"
        [src]="getImage(product?.overall_image)"
        alt="Hotel Image"
      />
      <!-- <span
        class="absolute top-2 left-2 bg-orange-500 text-white px-2 py-1 text-sm rounded"
        >-35%</span
      > -->
    </div>
    <div class="flex-1 flex flex-col justify-between p-3 pt-0">
      <div class="flex flex-col gap-1">
        <p-rating
          [ngModel]="product?.star"
          [readonly]="true"
          [cancel]="false"
        ></p-rating>
        <h3 class="text-sm font-semibold line-clamp-2">
          {{ product?.property_name }}
        </h3>
        <div class="text-gray-500 text-sm flex items-center gap-1">
          <i class="{{ SoctripIcons.MARKER_PIN_03 }} text-gray-400 mr-1"></i>
          <div class="line-clamp-1">{{ product?.address }}</div>
        </div>
        <div class="flex items-center gap-2 text-primary-500">
          <i class="sctr-icon-wifi"></i>
          <i class="sctr-icon-car"></i>
        </div>
      </div>
      <div class="flex flex-col items-end gap-[2px]">
        <span class="text-gray-400 line-through">{{
          product?.origin_price | currency
        }}</span>
        <span class="text-orange-dark-600 text-xl font-semibold">{{
          product?.net_price | currency
        }}</span>
      </div>
    </div>
  </div>

  <!-- FLIGHT -->
  <div
    *ngSwitchCase="'FLIGHT'"
    class="max-w-sm h-full bg-white rounded-lg border border-gray-200"
  >
    <div class="relative">
      <img
        class="rounded-t-lg w-full object-cover"
        style="aspect-ratio: 276 / 184; object-fit: cover"
        [src]="getImage(product?.image)"
        alt="Hotel Image"
      />
      <!-- <span
      class="absolute top-2 left-2 bg-orange-500 text-white px-2 py-1 text-sm rounded"
      >-35%</span
    > -->
    </div>
    <div class="px-5 py-4">
      <div class="flex items-start gap-2 pb-4">
        <img
          class="w-5 h-5 rounded-full"
          [src]="getImage(product?.thumbnail)"
        />
        <div class="flex flex-col gap-1">
          <span class="font-semibold line-clamp-2">{{ product?.name }}</span>
          <span class="text-gray-400 text-xs">{{
            product?.expired_date | date: "EEE, MMM dd yyyy"
          }}</span>
        </div>
      </div>
      <!-- <div class="flex justify-end items-center gap-2">
        <span class="text-gray-400 text-sm">From</span>
        <span class="text-orange-dark-600 text-xl font-semibold">$39.99</span>
      </div> -->
    </div>
  </div>

  <!-- TRAVEL TOUR -->
  <div
    *ngSwitchCase="'TRAVEL_TOUR'"
    class="max-w-sm h-full bg-white rounded-lg border border-gray-200"
  >
    <div class="relative">
      <img
        class="rounded-lg w-full object-cover"
        style="aspect-ratio: 276 / 184; object-fit: cover"
        [src]="getImage(product?.avatar)"
        alt="Hotel Image"
      />
      <!-- <span
        class="absolute top-2 left-2 bg-orange-500 text-white px-2 py-1 text-sm rounded"
        >-35%</span
      > -->
    </div>
    <div class="p-3">
      <div class="flex flex-col gap-1">
        <h3 class="text-sm font-medium line-clamp-2">
          {{ product.title }}
        </h3>
        <p class="text-gray-500 text-sm flex items-center">
          <i class="{{ SoctripIcons.MARKER_PIN_03 }} text-gray-400 mr-1"></i>
          <span
            *ngFor="let place of product?.destination_places; let last = last"
          >
            {{ place?.name }} {{ last ? "" : ", " }}
          </span>
        </p>
      </div>
      <div class="flex flex-col items-end gap-[2px]">
        <span class="text-gray-400 line-through">{{
          product?.original_price | currency
        }}</span>
        <span class="text-orange-dark-600 text-xl font-semibold">{{
          product?.price | currency
        }}</span>
      </div>
    </div>
  </div>

  <!-- CAR -->
  <div
    *ngSwitchCase="'CAR'"
    class="max-w-sm h-full bg-white rounded-lg border border-gray-200"
  >
    <div class="relative">
      <img
        class="rounded-t-lg w-full object-cover"
        style="aspect-ratio: 276 / 184; object-fit: cover"
        [src]="getImage(product?.thumbnail?.id)"
        alt="Car"
      />
      <!-- <span
        class="absolute top-2 left-2 bg-orange-500 text-white px-2 py-1 text-sm rounded"
        >-35%</span
      > -->
    </div>
    <div class="p-3">
      <div class="flex flex-col gap-3">
        <h3 class="text-sm font-semibold">{{ product?.name }}</h3>
        <div class="flex items-center flex-wrap gap-1">
          <span
            *ngFor="let tag of product?.tags"
            class="text-xs font-medium px-2 py-[2px] rounded-full bg-gray-100"
            >{{ tag }}</span
          >
          <!-- <span
            class="text-xs font-medium px-2 py-[2px] rounded-full bg-orange-dark-50 text-orange-dark-600"
            >Doorstep delivery</span
          > -->
        </div>
        <div class="flex flex-col gap-2">
          <p class="text-gray-500 text-sm flex items-center">
            <i class="{{ SoctripIcons.MARKER_PIN_03 }} text-gray-400 mr-1"></i>
            {{ product?.location }}
          </p>

          <div class="flex gap-2 items-center">
            <div class="flex gap-1 items-center">
              <i class="{{ SoctripIcons.THUMBS_UP }} text-primary-600"></i>
              <span class="text-gray-500 text-sm">{{ product?.rating }}</span>
            </div>

            <div class="rounded-full w-1 h-1 bg-gray-300"></div>

            <div class="flex gap-1 items-center">
              <i class="{{ SoctripIcons.CAR_01 }} text-success-600"></i>
              <span class="text-gray-500 text-sm"
                >{{ product?.quantity }} trips</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-end gap-1 mt-2">
        <span class="text-gray-400 line-through">{{
          product?.original_price | currency
        }}</span>
        <div>
          <div>
            <span class="text-orange-dark-600 text-xl font-semibold">{{
              product?.price | currency
            }}</span>
            <span class="text-gray-500">/day</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- SHOP -->
  <div
    *ngSwitchCase="'SHOP'"
    class="max-w-sm h-full bg-white rounded-lg border border-gray-200"
  >
    <div class="relative">
      <img
        style="aspect-ratio: 1 / 1; object-fit: cover"
        class="rounded-t-lg w-full object-cover"
        [src]="getImage(product?.avatar?.id)"
        alt="Hotel Image"
      />
    </div>
    <div class="px-3 py-[6px]">
      <div class="flex flex-col gap-1">
        <h3 class="text-sm font-medium line-clamp-2">
          {{ product?.name }}
        </h3>
        <div class="flex gap-1 items-center">
          <span class="text-gray-500 text-xs"
            >{{ product?.point }}
            <p-rating
              [stars]="1"
              [ngModel]="1"
              [readonly]="true"
              [cancel]="false"
            ></p-rating
          ></span>
          <span class="text-gray-500 text-xs">•</span>
          <span class="text-gray-500 text-xs">{{ product?.sold }} sold</span>
        </div>
      </div>
      <div class="flex flex-col mt-2">
        <span class="text-orange-dark-600 text-xl font-semibold">{{
          product?.price_after_tax | currency
        }}</span>
        <span class="text-gray-400 line-through">{{
          product?.original_price_after_tax | currency
        }}</span>
      </div>
    </div>
  </div>

  <!-- RESTAURANT -->
  <div
    *ngSwitchCase="'RESTAURANT'"
    class="max-w-sm h-full bg-white rounded-lg border border-gray-200"
  >
    <div class="relative">
      <img
        class="rounded-t-lg w-full object-cover"
        style="aspect-ratio: 227 / 162; object-fit: cover"
        src="https://www.wearegecko.co.uk/media/50316/mountain-3.jpg"
        alt="Hotel Image"
      />
    </div>
    <div class="px-4 py-3">
      <div class="flex flex-col gap-2">
        <h3 class="font-semibold">E&T Restaurant</h3>
        <div class="flex gap-1 items-center text-sm">
          <span
            >4.3
            <p-rating
              [stars]="1"
              [ngModel]="1"
              [readonly]="true"
              [cancel]="false"
            ></p-rating
          ></span>
          <span>•</span>
          <span>(215 reviews)</span>
        </div>
        <p class="text-sm flex items-center">
          <i
            class="{{ SoctripIcons.MARKER_PIN_03 }} text-primary-600  mr-1"
          ></i>
          TP. Tuy Hòa, Phú Yên
        </p>
      </div>
    </div>
  </div>
</div>

<ng-template #productDefaultTemplate>
  <div
    class="rounded-lg border flex flex-col justify-between overflow-hidden bg-gray-50 h-full"
  >
    <img
      class="rounded-lg w-full object-cover"
      style="aspect-ratio: 1 / 1; object-fit: cover"
      [src]="defaultData.image"
      alt="Hotel Image"
    />
    <div class="p-4 bg-white flex flex-col gap-2">
      <div class="font-medium text-sm line-clamp-1">
        {{ defaultData.title }}
      </div>
      <span class="text-xl text-orange-dark-500 font-semibold">$0</span>
    </div>
  </div>
</ng-template>
