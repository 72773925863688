import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CampaignBlockDTO as CampaignBlock,
  CampaignLayerDetailDTO as CampaignLayers,
  MarketingCampaignControllerService,
  MarketingCampaignModuleDTO as MarketingCampaignModule,
} from '@soctrip/angular-marketing-hub-service';
import { MarketingControllerService } from '@soctrip/angular-promotion-service';
import { DndDropEvent } from 'ngx-drag-drop';
import { catchError, forkJoin, map, of, switchMap } from 'rxjs';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import {
  BLOCK_TYPE,
  PREVIEW_DEVICE,
  PRODUCT_LIST_FILTER,
} from 'src/app/core/enum/marketing-builder';
import { Province } from 'src/app/core/models/interfaces/address';
import { Catalog } from 'src/app/core/models/interfaces/catalog';
import {
  Block,
  BlockFilter,
  ElementSelected,
  Layer,
  Media,
} from 'src/app/core/models/interfaces/marketing-builder';
import { FileService } from 'src/app/core/services/file.service';
import { MarketingHubService } from 'src/app/core/services/marketing-hub.service';
import { randomUUID } from 'src/app/core/utils/random-UUID';
import { MarketingData } from '../../components/header-builder/header-builder.component';
import { getProductFilters } from '../../utils/getProductFilters';

@Component({
  selector: 'app-landing-page-builder',
  templateUrl: './landing-page-builder.component.html',
  styleUrls: ['./landing-page-builder.component.scss'],
})
export class LandingPageBuilderComponent implements OnInit {
  readonly SoctripIcons = SoctripIcons;
  readonly BLOCK_TYPE = BLOCK_TYPE;
  readonly builderPrefix = 'landing-page-builder.';

  // LOCAL DATA
  previewDevice: string = PREVIEW_DEVICE.DESKTOP;
  mode: string = 'BUILDER';
  campaignId: string = '';
  layers: Layer[] = [
    {
      id: randomUUID(),
      code: 'ONE_ONE',
      textColor: '#101828',
      bgColor: '#FFFFFF',
      name: '',
      description: '',
      blocks: [],
      isNew: true,
    },
  ];
  elementSelected: ElementSelected = {
    scope: 'LAYER',
    layerIndex: 0,
    blockIndex: 0,
  };

  // SERVER DATA
  isLoading: boolean = false;
  layersData: CampaignLayers[] = [];
  categories: Catalog[] = [];

  marketing: MarketingData = {
    name: '',
    language: 'vn',
    module: 'SHOP',
    updatedAt: new Date(),
    link: '',
  };

  cities: Province[] = [];
  modules: MarketingCampaignModule[] = [];
  language: string = localStorage.getItem('lang') || 'en';

  constructor(
    public fileService: FileService,
    private marketingCampaignService: MarketingCampaignControllerService,
    private route: ActivatedRoute,
    private marketingControllerService: MarketingControllerService,
    private marketingHubService: MarketingHubService,
  ) {}

  ngOnInit(): void {
    this.fetchModules();
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== 'new') {
      this.isLoading = true;
      this.campaignId = id;

      this.marketingCampaignService
        .marketingCampaignsIdGet(id)
        .pipe(
          switchMap((res) => {
            this.marketing = {
              name: res.data?.title || '',
              language: res.data?.country_code || 'vn',
              module: res.data?.module_type || 'SHOP',
              updatedAt: res.data?.updated_at || new Date(),
              link: res.data?.link || '',
            };

            const layers = res?.data?.layers?.sort(
              (a, b) => (a.order || 0) - (b.order || 0),
            ) as CampaignLayers[];
            if (layers.length === 0)
              return of([
                {
                  id: randomUUID(),
                  code: 'ONE_ONE',
                  textColor: '#101828',
                  bgColor: '#FFFFFF',
                  name: '',
                  description: '',
                  blocks: [],
                  isNew: true,
                },
              ]);
            return this.convertLayersData(layers);
          }),
        )
        .subscribe({
          next: (res) => {
            this.isLoading = false;
            this.layers = [...res];
          },
        });
    }
  }

  fetchModules() {
    this.marketingCampaignService.marketingCampaignsModulesGet(this.language).subscribe({
      next: (res) => {
        if (res.data) {
          this.modules = res.data;
        }
      },
    });
  }

  convertLayersData(layouts: CampaignLayers[]) {
    return forkJoin(
      layouts.map((layout) => {
        const layerConvert: Layer = this.mapLayer(layout);
        const blocks = layout.blocks || [];
        if (blocks.length === 0) {
          return of([]).pipe(
            map((blocks) => ({ ...layerConvert, blocks: blocks })),
          );
        }

        return forkJoin(
          blocks.map((block) => {
            const blockConvert: Block = this.mapBlock(block);

            const filters = block.module_data_list?.map((item) => {
              if (!item.filter) return undefined;
              const value = JSON.parse(item.filter);

              if (item.selection_display_type === 'YYYYMMDD') {
                return {
                  ...item,
                  value: new Date(value),
                };
              }

              if (
                item.selection_display_type === 'RANGE' &&
                item.selection_choice_type === 'MILLISECONDS'
              ) {
                return {
                  ...item,
                  value: {
                    from: new Date(value?.from),
                    to: new Date(value?.to),
                  },
                };
              }

              return {
                ...item,
                value: value,
              };
            }) as BlockFilter[];
            const specific = block.module_data_list?.find(
              (item) => item.selection_display_type === 'SPECIFIC',
            );
            const specificProducts = specific
              ? JSON.parse(specific?.filter || '')
              : [];

            const voucherIds = block?.objects?.map((obj) => obj.object_id);

            const productFilters: string[] = [];

            if (!!specific && specificProducts?.length) {
              productFilters.push(
                `id==${specificProducts
                  .map((product: any) => product.id)
                  .join('|')}`,
              );
            }

            const filtersString = encodeURIComponent(
              getProductFilters(filters, !!specific, specificProducts),
            );

            const sortString = encodeURIComponent(block.sort || '');
            return forkJoin({
              products:
                block.code === BLOCK_TYPE.PRODUCT_LIST
                  ? this.marketingHubService
                      .getModuleData(
                        block.module || '',
                        filtersString,
                        0,
                        25,
                        sortString,
                      )
                      .pipe(catchError(() => of(undefined)))
                  : of(undefined),
              vouchers: voucherIds?.length
                ? this.marketingControllerService
                    .findMultiVouchersByIds(
                      voucherIds.filter((id): id is string => id !== undefined),
                    )
                    .pipe(catchError(() => of(undefined)))
                : of(undefined),
            }).pipe(
              map(({ products, vouchers }) => {
                const specific = block.module_data_list?.find(
                  (item) => item.selection_display_type === 'SPECIFIC',
                );
                return {
                  ...blockConvert,
                  filters: filters,
                  sorts: this.getSorts(block.sort),
                  products: products?.data?.data?.data,
                  specific_products: specific
                    ? JSON.parse(specific?.filter || '')
                    : [],
                  isSpecificProduct: !!specific,
                  vouchers: vouchers?.data as any[],
                };
              }),
            );
          }),
        ).pipe(map((blocks) => ({ ...layerConvert, blocks: blocks })));
      }),
    );
  }

  private mapLayer(layer: CampaignLayers): Layer {
    return {
      id: layer.id || '',
      code: layer.code || 'ONE_ONE',
      name: layer.title || '',
      textColor: layer.color || '#000000',
      bgColor: layer.background_color || '#ffffff',
      description: layer.description || '',
      isNew: false,
      blocks: [],
    };
  }

  private mapBlock(block: CampaignBlock): Block {
    return {
      flex: block.desktop_row || 1,
      type: block.code || '',
      medias: this.mapMedia(block.media),
      text: block.text || '',
      description: block.description || '',
      isStick: block.position === 'STICKY' ? true : false,
      module: block.module || 'SHOP',
      line: block.line || 1,
      itemPerLine: block.desktop_items_per_line || 2,
      isSpecificProduct: false,
      specific_products: [],
      sorts: [],
      filters: [],
      vouchers: [],
    };
  }

  private mapMedia(media?: any[]): Media[] {
    return (
      media?.map((item) => ({
        isLoading: false,
        media1: item.media_desktop_id
          ? {
              id: item.media_desktop_id,
              name: item.desktop_name || '',
              size: item.desktop_size || '',
            }
          : undefined,
        media2: item.media_mobile_id
          ? {
              id: item.media_mobile_id,
              name: item.mobile_name || '',
              size: item.mobile_size || '',
            }
          : undefined,
        link1: item.desktop_link || '',
        link2: item.mobile_link || '',
        alignment: '',
        isOpenNewTab: item.is_open_in_new_tab,
        reference_id: item.reference_id,
      })) || []
    );
  }

  private getSorts(sorts?: string): Array<{ key: string; value: number }> {
    if (!sorts) return [];

    return sorts.split(',').map((sort) => {
      const isDescending = sort.startsWith('-');
      const key = sort.replace('-', '').trim();

      return {
        key: key.trim(),
        value: isDescending ? -1 : 1,
      };
    });
  }

  onDropBlock(event: DndDropEvent, layerIndex: number, blockIndex: number) {
    const data = event.data;
    if (data.tab !== 'BLOCK') return;

    const type = data.data.value;

    switch (type) {
      case BLOCK_TYPE.IMAGE: {
        this.layers[layerIndex].blocks[blockIndex] = {
          ...this.layers[layerIndex].blocks[blockIndex],
          medias: [
            {
              media1: undefined,
              media2: undefined,
              alignment: 'LEFT',
              isOpenNewTab: false,
              link1: '',
            },
          ],
        };
        break;
      }
      case BLOCK_TYPE.TEXT: {
        this.layers[layerIndex].blocks[blockIndex] = {
          ...this.layers[layerIndex].blocks[blockIndex],
          text: '',
          description: '',
        };
        break;
      }
      case BLOCK_TYPE.SLIDE: {
        this.layers[layerIndex].blocks[blockIndex] = {
          ...this.layers[layerIndex].blocks[blockIndex],
          medias: [
            {
              isLoading: false,
              link1: '',
              link2: '',
            },
          ],
        };
        break;
      }
      case BLOCK_TYPE.VIDEO: {
        this.layers[layerIndex].blocks[blockIndex] = {
          ...this.layers[layerIndex].blocks[blockIndex],
          medias: [
            {
              media1: undefined,
              isLoading: false,
              alignment: 'LEFT',
              link2: '',
            },
          ],
        };
        break;
      }
      case BLOCK_TYPE.TAB: {
        this.layers[layerIndex].blocks[blockIndex] = {
          ...this.layers[layerIndex].blocks[blockIndex],
          medias: [],
        };
        break;
      }
      case BLOCK_TYPE.PRODUCT_LIST: {
        this.layers[layerIndex].blocks[blockIndex] = {
          ...this.layers[layerIndex].blocks[blockIndex],
          line: 1,
          itemPerLine: 4,
        };
        break;
      }
      case BLOCK_TYPE.VOUCHER: {
        this.layers[layerIndex].blocks[blockIndex] = {
          ...this.layers[layerIndex].blocks[blockIndex],
          line: 1,
          itemPerLine: 2,
        };
        break;
      }
      default: {
        return;
      }
    }

    this.layers[layerIndex].blocks[blockIndex].type = type;
    this.layers = [...this.layers];
  }

  onDropLayout(event: DndDropEvent, index: number) {
    const data = event.data;

    if (data.tab !== 'LAYOUT') return;

    const layout: number[] = data.data.value;
    this.layers[index].blocks = layout.map((item) => ({
      flex: item,
      type: '',
      medias: [],
      text: '',
      description: '',
      isStick: false,
      module: 'SHOP',
      line: 1,
      itemPerLine: 4,
      sorts: [],
      filters: [],
      vouchers: [],
      specific_products: [],
      isSpecificProduct: false,
    }));

    this.layers = [...this.layers];
  }

  onAddLayer(event: Event, index: number) {
    event.stopPropagation();

    this.layers.splice(index + 1, 0, {
      id: randomUUID(),
      code: 'ONE_ONE',
      textColor: '#101828',
      bgColor: '#FFFFFF',
      name: '',
      description: '',
      blocks: [],
      isNew: true,
    });
    this.layers = [...this.layers];

    this.elementSelected = {
      scope: 'LAYER',
      layerIndex: index + 1,
      blockIndex: 0,
    };
  }

  onClickLayer(event: Event, index: number) {
    event.stopPropagation();
    this.elementSelected = {
      scope: 'LAYER',
      layerIndex: index,
      blockIndex: 0,
    };
  }

  onClickBlock(event: Event, layerIndex: number, blockIndex: number) {
    event.stopPropagation();
    this.elementSelected = {
      scope: 'BLOCK',
      layerIndex,
      blockIndex,
    };
  }

  onDropLayer(event: CdkDragDrop<Layer[]>) {
    moveItemInArray(this.layers, event.previousIndex, event.currentIndex);
    this.layers = [...this.layers];

    this.elementSelected = {
      ...this.elementSelected,
      layerIndex: event.currentIndex,
    };
  }

  onDeleteLayer(index: number) {
    if (this.layers.length === 1) {
      this.layers = [
        {
          id: randomUUID(),
          code: 'ONE_ONE',
          textColor: '#101828',
          bgColor: '#FFFFFF',
          name: '',
          description: '',
          blocks: [],
          isNew: true,
        },
      ];

      this.elementSelected = {
        scope: 'LAYER',
        layerIndex: 0,
        blockIndex: 0,
      };
    } else {
      this.layers.splice(index, 1);

      const newLayerIndex = Math.max(0, index - 1);
      this.elementSelected = {
        scope: 'LAYER',
        layerIndex: newLayerIndex,
        blockIndex: 0,
      };
    }

    this.layers = [...this.layers];
  }

  onDeleteBlock(layerIndex: number, blockIndex: number) {
    const layer = this.layers[layerIndex];

    if (!layer || layer.blocks.length === 0) {
      return;
    }
    const defaultBlock: Block = {
      flex: layer.blocks[blockIndex].flex ?? 1,
      type: '',
      medias: [],
      text: '',
      description: '',
      isStick: false,
      module: 'SHOP',
      line: 1,
      itemPerLine: 4,
      sorts: [],
      filters: [],
      vouchers: [],
      isSpecificProduct: false,
      specific_products: [],
    };
    layer.blocks[blockIndex] = { ...defaultBlock };
  }

  onCloneLayer(index: number) {
    const newLayer = JSON.parse(JSON.stringify(this.layers[index]));
    this.layers.splice(index + 1, 0, {
      ...newLayer,
      id: randomUUID(),
      isNew: true,
    });

    this.layers = [...this.layers];
  }

  generateArray(length: number) {
    return Array(length).fill(1);
  }

  getGridTemplate(row: number) {
    return `repeat(${row}, minmax(0, 1fr))`;
  }

  findCatalogById(id: string, catalogs: Catalog[]): Catalog | null {
    for (const catalog of catalogs) {
      if (catalog.id === id) {
        return catalog;
      }
      if (catalog.sub_catalogs && catalog.sub_catalogs.length > 0) {
        const result = this.findCatalogById(id, catalog.sub_catalogs);
        if (result) return result;
      }
    }
    return null;
  }

  mapIdsToCatalog(ids: string[], catalogs: Catalog[]) {
    // Hàm đệ quy để tìm catalog và các sub-catalogs

    return ids.map((id) => {
      const catalog = this.findCatalogById(id, catalogs);
      if (!catalog) {
        throw new Error(`Catalog with ID ${id} not found`);
      }
      return {
        id: catalog.id,
        depth: catalog.depth,
        image: catalog.image,
        name: catalog.name,
        sub_catalogs: catalog.sub_catalogs, // Giữ nguyên các sub_catalogs
      };
    });
  }

  formatDiscountMinSpend(voucher: any): string {
    const discountRange = voucher?.voucher_discount_range?.[0];
    if (!discountRange) return '';

    const currency = '$';
    return `Min spend ${currency}${
      discountRange.min_order_price
        ? discountRange.min_order_price.toFixed(2)
        : ''
    }`;
  }

  getMaxWidth(flex: number, blocks: Block[]): string {
    if (blocks.length <= 1) {
      return '100%';
    }

    const totalFlex = blocks.reduce(
      (sum: number, block: Block) => sum + block.flex,
      0,
    );

    return `calc(${(100 / totalFlex) * flex}% - ${16 / (blocks.length - 1)}px)`;
  }
}
