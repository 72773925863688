import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { GlobalCatalogControllerService } from '@soctrip/angular-catalog-service';
import { Subject, debounceTime } from 'rxjs';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { BlockEntityData } from 'src/app/core/models/interfaces/marketing-builder';
import { ProductDetails } from 'src/app/core/models/interfaces/product';
import { FileService } from 'src/app/core/services/file.service';
import { MarketingHubService } from 'src/app/core/services/marketing-hub.service';

@Component({
  selector: 'app-add-product-modal',
  templateUrl: './add-product-modal.component.html',
  styleUrls: ['./add-product-modal.component.scss'],
})
export class AddProductModalComponent implements OnInit, OnChanges {
  protected readonly SoctripIcons = SoctripIcons;

  @Input({ required: true }) module: string = 'SHOP';
  @Input({ required: true }) isModal = false;
  @Input({ required: true }) selectedProducts: BlockEntityData[] = [];

  @Output() isModalChange = new EventEmitter<boolean>();
  @Output() selectedProductsChange = new EventEmitter<any[]>();

  _products: ProductDetails[] = [];
  _totalProduct = 0;
  isFetching = false;
  isFetched = false;

  RATINGS = [1, 2, 3, 4, 5];
  rating: number | null = null;
  _categories: any[] = [];
  categories: any[] = [];
  pagination = {
    rows: 10,
    page: 0,
  };
  keyword = '';
  keywordChanged = new Subject<string>();
  language: string = localStorage.getItem('lang') || 'en';
  columns: any = [];

  constructor(
    private globalCatalogService: GlobalCatalogControllerService,
    public fileService: FileService,
    private httpClient: HttpClient,
    private marketingService: MarketingHubService,
  ) {}

  ngOnInit() {
    this.keywordChanged.pipe(debounceTime(800)).subscribe({
      next: (keyword) => {
        this.keyword = keyword;
        this._fetchProducts(true);
      },
    });
    this._fetchCategories();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['module']?.currentValue) {
      this.categories = [];
      this.keyword = '';
      this.rating = null;

      this._fetchProducts(true);
    }
  }

  _fetchCategories() {
    this.globalCatalogService.globalCatalogsTreeGet(this.language).subscribe({
      next: (res) => {
        if (res?.data) {
          this._categories = this.convertTreeNode(res);
        }
      },
    });
  }

  convertTreeNode(res: any) {
    const flatData: any[] = res.data;
    const buildTree = (
      flatNodes: any[],
      parentId: string | null = null,
    ): any[] => {
      const tree: any[] = [];

      for (const node of flatNodes) {
        if (node.parent_id === parentId) {
          const newNode: any = {
            key: node.id,
            label: node.name,
          };
          if (node.sub_catalogs && node.sub_catalogs.length > 0) {
            newNode.children = buildTree(node.sub_catalogs, node.id);
          }
          tree.push(newNode);
        }
      }
      return tree;
    };
    const findRoots = (flatNodes: any[]): any[] => {
      const parentIds = new Set(flatNodes.map((node) => node.parent_id));
      return flatNodes.filter((node) => !parentIds.has(node.id));
    };

    const roots = findRoots(flatData);
    const treeData = buildTree(roots, null);
    return treeData;
  }

  _fetchProducts(resetPage: boolean) {
    if (resetPage) {
      this.onResetPagination();
    }
    this.isFetching = true;

    const catalog_ids = this.categories
      ?.map((category) => category.key)
      .join('|');

    const filters = [
      catalog_ids ? `catalog_ids==${catalog_ids}` : '',
      this.keyword ? `name@=${this.keyword}` : '',
      this.rating ? `point>=${this.rating}` : '',
    ]
      .filter((i) => i)
      .join(',');

    const filtersString = encodeURIComponent(filters);

    this.marketingService
      .getModuleData(
        this.module,
        filtersString,
        this.pagination.page,
        this.pagination.rows,
        '',
        this.language
      )

      .subscribe({
        next: (res: any) => {
          this._products = res.data.data?.data || [];
          this._totalProduct = res.data?.data?.totalElement || 0;

          this.columns = res.data?.module_keywords;
          this.isFetching = false;
        },
        error: () => {
          this.isFetching = false;
        },
      });
  }

  onRemoveCategory(event: any, index: number) {
    event.stopPropagation();
    this.categories.splice(index, 1);
    this._fetchProducts(true);
  }

  onResetPagination() {
    this.pagination = {
      page: 0,
      rows: 10,
    };
  }

  setIsModal(isModal: boolean) {
    this.isModal = isModal;
    this.isModalChange.emit(this.isModal);
  }

  onChangeSelectedProducts(e: any) {
    this.selectedProducts = e;
  }

  onAddProducts() {
    this.setIsModal(false);
    this.selectedProductsChange.emit(this.selectedProducts);
  }

  onCancelAddProduct() {
    this.setIsModal(false);
  }

  getValueWithOptionalChaining(obj: any, path: string) {
    if (!obj || !path) return null;

    if (obj.hasOwnProperty(path)) {
      return Array.isArray(obj[path]) ? obj[path].join(', ') : obj[path];
    }

    path = path.replace(/\?/g, '');

    const keys = path.split('.');
    let value = obj;

    for (let key of keys) {
      if (value?.[key] === undefined) {
        break;
      }
      value = value[key];
    }

    if (Array.isArray(value)) {
      return value
        .map((item) =>
          typeof item === 'object' ? item[keys[keys.length - 1]] : item,
        )
        .filter((item) => item !== undefined)
        .join(', ');
    }

    return value || '-';
  }
}
