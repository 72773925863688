<ng-container [ngSwitch]="filterData.selection_display_type">
  <div class="p-4 border bg-gray-50 rounded-lg flex flex-col gap-3">
    <div class="flex items-center justify-between">
      <span class="text-xs font-medium uppercase"
        >{{ getFilterName(filterData.code) }}
        <span *ngIf="isRequired" class="text-orange-dark-600">*</span>
      </span>

      <i
        *ngIf="!isRequired"
        class="{{
          SoctripIcons.X_CLOSE
        }} cursor-pointer text-gray-500 hover:text-gray-900"
        (click)="onDeleteSelection()"
      ></i>
    </div>

    <!-- FILTER -->
    <ng-container>
      <!-- CAMPAIGNS -->
      <div *ngSwitchCase="PRODUCT_LIST_FILTER.CAMPAIGN">
        <div
          class="button"
          [ngClass]="isModal ? 'bg-gray-600/10' : 'bg-white'"
          (click)="onToggle()"
        >
          {{ "section-action.select" | translate | sentenceCase }}
        </div>
        <div *ngIf="filter.value?.length" class="flex flex-wrap gap-2 pt-3">
          <div
            *ngFor="let campaign of filter.value; let i = index"
            class="h-7 rounded-full flex items-center gap-1 bg-gray-200 pr-2 w-fit max-w-full"
          >
            <span class="text-sm font-medium truncate">
              {{ campaign.title }}</span
            >
            <i
              class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
              (click)="onDeleteItem(campaign)"
            ></i>
          </div>
        </div>
        <p-overlay [(visible)]="isModal" appendTo="body">
          <ul
            class="bg-white rounded-lg border w-[472px] max-h-[360px] p-1 mt-1 overflow-y-auto"
          >
            <div
              *ngIf="!data?.length"
              class="py-4 text-center text-sm font-medium"
            >
              No data
            </div>
            <li
              *ngFor="let campaign of data"
              class="h-[60px] flex items-center gap-3 px-3"
            >
              <p-checkbox
                [value]="{
                  id: campaign.id,
                  title: campaign.title,
                  thumbnail: campaign.filter_thumbnail
                }"
                [(ngModel)]="filter.value"
                (onChange)="changeData.emit()"
              ></p-checkbox>
              <app-custom-image
                styleClass="w-10 h-10"
                imageClass="rounded"
                [imgId]="campaign.filter_thumbnail || ''"
                [alt]="campaign.title || ''"
              ></app-custom-image>

              <div class="flex flex-col gap-1">
                <div class="flex items-center gap-1">
                  <span class="text-sm font-medium">{{ campaign.title }}</span>
                  <span
                    class="text-xs px-2 py-[2px] rounded-full font-medium text-primary-600 bg-primary-50"
                    >{{
                      "section-status.on-going" | translate | sentenceCase
                    }}</span
                  >
                </div>
                <div>
                  <span class="text-sm text-gray-500"
                    >{{ campaign.start_at | date: "MM/dd/yyyy" }} -
                    {{
                      (campaign.end_at | date: "MM/dd/yyyy") ||
                        (builderPrefix + "no-expired-date" | translate)
                    }}
                    • {{ campaign.total_products }}
                    {{ "common.products" | translate }}</span
                  >
                </div>
              </div>
            </li>
          </ul>
        </p-overlay>
      </div>

      <!-- KEYWORDS -->
      <ng-container *ngSwitchCase="PRODUCT_LIST_FILTER.KEYWORD">
        <input
          [(ngModel)]="keyword"
          class="h-9"
          type="text"
          pInputText
          placeholder="{{ builderPrefix + 'enter-keyword' | translate }}"
          (keyup.enter)="onAddKeyword($event)"
        />

        <div *ngIf="filter.value?.length" class="flex flex-wrap gap-2">
          <div
            *ngFor="let keyword of filter.value; let i = index"
            class="h-7 rounded-full flex items-center gap-1 bg-gray-200 pl-3 pr-2 w-fit max-w-full"
          >
            <span class="text-sm font-medium">{{ keyword }}</span>
            <i
              class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
              (click)="onDeleteItem(keyword)"
            ></i>
          </div>
        </div>
      </ng-container>

      <!-- CATEGORY_TREE -->
      <ng-container *ngSwitchCase="PRODUCT_LIST_FILTER.CATEGORY_TREE">
        <div>
          <div
            class="button"
            [ngClass]="isModal ? 'bg-gray-600/10' : 'bg-white'"
            (click)="isModal = !isModal"
          >
            {{ "section-action.select" | translate | sentenceCase }}
          </div>
          <p-overlay appendTo="body" [(visible)]="isModal">
            <div
              class="mb-1 flex w-[1000px] h-[25rem] overflow-x-auto overflow-y-clip border bg-white border-gray-200 shadow-lg rounded-lg"
            >
              <div
                *ngFor="let categories of data"
                class="overflow-auto border-r border-gray-200 w-1/5"
              >
                <div
                  *ngFor="let category of categories"
                  (mouseover)="hoverCategory($event, category)"
                  (click)="selectCategory($event, category)"
                  [ngClass]="{
                    'bg-primary-50 text-primary-700': isCategoryAdded(
                      category.id
                    )
                  }"
                  class="flex justify-between items-center p-2 gap-2 hover:bg-primary-50 hover:text-primary-700 hover:cursor-pointer"
                >
                  <span class="text-sm line-clamp-1">{{ category.name }}</span>
                  <i
                    *ngIf="category.sub_catalogs"
                    [class]="SoctripIcons.CHEVRON_RIGHT"
                  ></i>
                </div>
              </div>
            </div>
          </p-overlay>
        </div>

        <div *ngIf="filter.value?.length" class="flex flex-wrap gap-2">
          <div
            *ngFor="let category of filter.value; let i = index"
            class="h-7 rounded-full flex items-center gap-1 bg-gray-200 pl-3 pr-2 w-fit max-w-full"
          >
            <span class="text-sm font-medium">{{ category.name }}</span>
            <i
              class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
              (click)="onDeleteItem(category)"
            ></i>
          </div>
        </div>
      </ng-container>

      <!-- RATING -->
      <ng-container *ngSwitchCase="PRODUCT_LIST_FILTER.RATING">
        <div>
          <div
            class="button"
            [ngClass]="isModal ? 'bg-gray-600/10' : 'bg-white'"
            (click)="isModal = !isModal"
          >
            {{ "section-action.select" | translate | sentenceCase }}
          </div>
          <p-overlay appendTo="body" [(visible)]="isModal">
            <div
              class="mt-1 p-1 bg-white w-[284px] rounded-lg max-h-[400px] overflow-y-auto border shadow-md"
            >
              <div
                class="p-3 flex items-center gap-3"
                *ngFor="let STAR of STARS"
              >
                <p-radioButton
                  [value]="STAR"
                  [(ngModel)]="filter.value"
                ></p-radioButton>

                <p-rating [ngModel]="STAR" [cancel]="false" [readonly]="true" />
              </div>
            </div>
          </p-overlay>
        </div>

        <div *ngIf="filter.value" class="flex flex-wrap gap-2">
          <div
            class="h-7 rounded-full flex items-center gap-1 bg-gray-200 pl-3 pr-2 w-fit max-w-full"
          >
            <div class="flex items-center gap-1">
              <p-rating
                class="flex"
                [stars]="1"
                [ngModel]="1"
                [cancel]="false"
                [readonly]="true"
              />
              <span class="text-sm font-medium"> {{ filter.value }}</span>
            </div>
            <i
              class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
              (click)="onDeleteItem()"
            ></i>
          </div>
        </div>
      </ng-container>

      <!-- DROPDOWN -->
      <ng-container *ngSwitchCase="PRODUCT_LIST_FILTER.DROPDOWN">
        <div>
          <div
            class="button"
            [ngClass]="isModal ? 'bg-gray-600/10' : 'bg-white'"
            (click)="isModal = !isModal"
          >
            {{ "section-action.select" | translate | sentenceCase }}
          </div>

          <p-overlay appendTo="body" [(visible)]="isModal">
            <div
              class="mt-1 p-1 bg-white w-[284px] rounded-lg border shadow-md"
            >
              <ng-container>
                <div *ngIf="filterData.table_filter_keywords" class="p-1">
                  <input
                    class="h-9 w-full"
                    type="text"
                    pInputText
                    [(ngModel)]="searchString"
                    (ngModelChange)="searchSubject.next($event)"
                  />
                </div>
                <div
                  infiniteScroll
                  [infiniteScrollDistance]="0.8"
                  [infiniteScrollThrottle]="300"
                  [scrollWindow]="false"
                  (scrolled)="onLazyLoad()"
                  *ngIf="data?.length"
                  class="max-h-[300px] overflow-y-auto"
                >
                  <div
                    class="p-3 flex items-center gap-3"
                    *ngFor="let item of data"
                  >
                    <p-checkbox
                      *ngIf="filterData.selection_choice_type === 'CHECKBOX'"
                      [value]="item"
                      [(ngModel)]="filter.value"
                      (onChange)="changeData.emit()"
                    ></p-checkbox>

                    <p-radioButton
                      *ngIf="filterData.selection_choice_type === 'RADIO'"
                      [inputId]="item[filterData.filter_column || '']"
                      [name]="filterData.code || ''"
                      [value]="item"
                      [(ngModel)]="filter.value"
                      (onClick)="changeData.emit()"
                    ></p-radioButton>

                    <span class="text-sm font-medium">{{
                      item?.[filterData.module_keywords?.[0]?.field_name || ""]
                    }}</span>
                  </div>

                  <div *ngIf="isLoading" class="text-center p-1">
                    <i class="pi pi-spin pi-spinner"></i>
                  </div>
                </div>
              </ng-container>
              <div
                class="flex items-center justify-center py-10"
                *ngIf="!data?.length"
              >
                <div *ngIf="isLoading">
                  <i class="pi pi-spin pi-spinner"></i>
                </div>
                <div *ngIf="!isLoading" class="text-sm font-medium">
                  No data
                </div>
              </div>
            </div>
          </p-overlay>
        </div>

        <ng-container
          *ngIf="
            filterData.selection_choice_type === 'CHECKBOX';
            else dropdownSingle
          "
        >
          <div *ngIf="filter.value?.length" class="flex flex-wrap gap-2">
            <div
              *ngFor="let city of filter.value; let i = index"
              class="h-7 rounded-full flex items-center gap-1 bg-gray-200 pl-3 pr-2 w-fit max-w-full"
            >
              <span class="text-sm font-medium">
                {{
                  city?.[filterData.module_keywords?.[0]?.field_name || ""]
                }}</span
              >
              <i
                class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
                (click)="onDeleteItem(city)"
              ></i>
            </div>
          </div>
        </ng-container>
        <ng-template #dropdownSingle>
          <div
            *ngIf="filter.value?.[filter.filter_column || '']"
            class="h-7 rounded-full flex items-center gap-1 bg-gray-200 pl-3 pr-2 w-fit max-w-full"
          >
            <span class="text-sm font-medium">
              {{
                filter.value?.[
                  filterData.module_keywords?.[0]?.field_name || ""
                ]
              }}</span
            >
            <i
              class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
              (click)="onDeleteItem(filter.value)"
            ></i>
          </div>
        </ng-template>
      </ng-container>

      <!-- AGENCY -->
      <ng-container *ngSwitchCase="PRODUCT_LIST_FILTER.AGENCY">
        <div>
          <div
            class="button"
            [ngClass]="isModal ? 'bg-gray-600/10' : 'bg-white'"
            (click)="isModal = !isModal"
          >
            {{ "section-action.select" | translate | sentenceCase }}
          </div>

          <app-add-shops-modal
            [(isModal)]="isModal"
            [moduleDataId]="moduleDataId || ''"
            [filter]="filterData"
            [(selectedShops)]="filter.value"
            (selectedShopsChange)="changeData.emit()"
            [cities]="cities"
          >
          </app-add-shops-modal>
        </div>

        <ng-container
          *ngIf="
            filterData.selection_choice_type === 'CHECKBOX';
            else agencySingle
          "
        >
          <div *ngIf="filter.value?.length" class="flex flex-wrap gap-2">
            <div
              *ngFor="let product of filter.value; let i = index"
              class="h-7 rounded-full flex items-center gap-1 bg-gray-200 p-1 pr-2 w-fit max-w-full"
            >
              <div class="w-5 min-w-[20px] h-5">
                <app-custom-image
                  styleClass="w-full h-full rounded-full overflow-hidden"
                  [imgId]="product.avatar"
                  [alt]="product.name"
                ></app-custom-image>
              </div>
              <span class="text-sm font-medium truncate">
                {{ product.name }}</span
              >
              <i
                class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
                (click)="onDeleteItem(product)"
              ></i>
            </div>
          </div>
        </ng-container>

        <ng-template #agencySingle>
          <div
            *ngIf="filter.value?.[filter.filter_column || '']"
            class="h-7 rounded-full flex items-center gap-1 bg-gray-200 p-1 pr-2 w-fit max-w-full"
          >
            <div class="w-5 min-w-[20px] h-5">
              <div class="w-5 min-w-[20px] h-5">
                <app-custom-image
                  styleClass="w-full h-full rounded-full overflow-hidden"
                  [imgId]="filter.value?.avatar"
                  [alt]="filter.value?.name"
                ></app-custom-image>
              </div>
            </div>
            <span class="text-sm font-medium truncate">
              {{ filter.value.name }}</span
            >
            <i
              class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
              (click)="onDeleteItem(filter.value)"
            ></i>
          </div>
        </ng-template>
      </ng-container>

      <!-- YYYYMMDD -->
      <ng-container *ngSwitchCase="PRODUCT_LIST_FILTER.YYYYMMDD">
        <p-calendar
          appendTo="body"
          class="w-full"
          styleClass="w-full"
          [placeholder]="'Select ' + filter.name"
          [(ngModel)]="filter.value"
          (onSelect)="changeData.emit()"
        ></p-calendar>
      </ng-container>

      <!-- RANGE -->
      <ng-container *ngSwitchCase="PRODUCT_LIST_FILTER.RANGE">
        <div
          *ngIf="filter.selection_choice_type !== 'MILLISECONDS'"
          class="flex flex-col items-center gap-2"
        >
          <div class="flex flex-row items-center gap-2">
            <p-inputNumber
              mode="currency"
              currency="USD"
              locale="en-US"
              inputStyleClass="w-[132px] h-9"
              [(ngModel)]="filter.value.from"
              (ngModelChange)="onFromChange($event)"
            />
            <span>-</span>
            <p-inputNumber
              mode="currency"
              currency="USD"
              locale="en-US"
              inputStyleClass="w-[132px] h-9"
              [(ngModel)]="filter.value.to"
              (ngModelChange)="onToChange($event)"
            />
          </div>
          <div *ngIf="isToInvalid" class="text-error-600 text-xs font-normal mt-1">
            <span>{{
              builderPrefix + "invalid-price-range" | translate | sentenceCase
            }}</span>
          </div>
        </div>

        <div
          *ngIf="filter.selection_choice_type === 'MILLISECONDS'"
          class="flex items-center gap-2"
        >
          <p-calendar
            inputStyleClass="!h-9"
            [(ngModel)]="filter.value.from"
            appendTo="body"
          ></p-calendar>
          <span>-</span>
          <p-calendar
            inputStyleClass="!h-9"
            [(ngModel)]="filter.value.to"
            appendTo="body"
          ></p-calendar>
        </div>
      </ng-container>

      <!-- NUMBER -->
      <ng-container *ngSwitchCase="PRODUCT_LIST_FILTER.NUMBER">
        <p-inputNumber
          styleClass="w-full"
          inputStyleClass="h-9"
          [(ngModel)]="filter.value"
        />
      </ng-container>
    </ng-container>
  </div>
</ng-container>
