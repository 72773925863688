import {
  Component,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FlashDealCampaign } from '@soctrip/angular-advertising-service';
import {
  MarketingCampaignModuleDTO as MarketingCampaignModule,
  ModuleDataControllerService,
  ModuleDataDTO,
  ModuleSelectionDTO as ModuleSelection,
  ModuleSelectionControllerService,
  RequiredSelectionDTO,
} from '@soctrip/angular-marketing-hub-service';
import { InputNumberInputEvent } from 'primeng/inputnumber';
import { forkJoin } from 'rxjs';
import {
  FILTER_TAB,
  SORT_ITEMS,
  STARS,
} from 'src/app/core/constants/marketing-builder';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import {
  FILTER_TAB_ENUM,
  PRODUCT_LIST_FILTER,
} from 'src/app/core/enum/marketing-builder';
import {
  BlockEntityData,
  BlockFilter,
  ElementSelected,
  Layer,
} from 'src/app/core/models/interfaces/marketing-builder';
import { MarketingHubService } from 'src/app/core/services/marketing-hub.service';
import { getProductFilters } from 'src/app/modules/landing-page/utils/getProductFilters';

@Component({
  selector: 'app-setup-products',
  templateUrl: './setup-products.component.html',
  styleUrls: ['./setup-products.component.scss'],
})
export class SetupProductsComponent implements OnChanges {
  @Input() elementSelected: ElementSelected;
  @Input() layers: Layer[] = [];
  @Input() modules: MarketingCampaignModule[] = [];

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.isModalFilter = false;
    this.isModalSort = false;
  }

  readonly SoctripIcons = SoctripIcons;
  readonly STARS = STARS;
  readonly PRODUCT_LIST_FILTER = PRODUCT_LIST_FILTER;
  readonly builderPrefix = 'landing-page-builder.';
  readonly filterTabEnum = FILTER_TAB_ENUM;
  readonly LIMIT_TOTAL_PRODUCT = 10000;

  filterTab = FILTER_TAB;
  isCounting: boolean = false;
  isModalFilter: boolean = false;
  isModalSort: boolean = false;
  isModalProduct: boolean = false;
  isInValid: boolean = false;

  promotionPrograms: FlashDealCampaign[] = [];
  currentFilter = PRODUCT_LIST_FILTER;

  filtersBy: ModuleSelection[] = [];
  sortsBy: {
    name: string;
    value: string;
    scope: string;
    disabled: boolean;
  }[] = [];
  moduleData?: ModuleDataDTO = undefined;
  modulesShow: MarketingCampaignModule[] = [];
  isLoading: boolean = false;
  language: string = localStorage.getItem('lang') || 'en';
  constructor(
    private moduleSelectionService: ModuleSelectionControllerService,
    private marketingHubService: MarketingHubService,
    private moduleDataService: ModuleDataControllerService,
  ) {}

  get blockData() {
    return this.layers[this.elementSelected.layerIndex].blocks[
      this.elementSelected.blockIndex
    ];
  }

  get isHasSpecificProduct() {
    return !['HOTEL', 'CAR', 'FLIGHT'].includes(this.blockData.module);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['elementSelected']?.currentValue) {
      this.isModalFilter = false;
      this.isModalSort = false;
      this.isModalProduct = false;
      this.fetchModuleFilters();

      this.onCountProduct();
    }

    if (changes?.['modules']?.currentValue) {
      this.modulesShow = [...this.modules].filter(
        (module) =>
          module.code !== 'ALL' &&
          module.code !== 'SOCIAL' &&
          module.code !== 'RESTAURANT',
      );
    }
  }

  fetchModuleFilters() {
    this.isLoading = true;

    this.filterTab = [...FILTER_TAB].filter((item) =>
      this.isHasSpecificProduct
        ? true
        : item.value !== FILTER_TAB_ENUM.SPECIFIC_PRODUCTS,
    );
    forkJoin({
      filters: this.moduleSelectionService.moduleSelectionsModuleTypeGet(
        this.blockData.module,
        0,
        20,
        'FILTER',
        undefined,
        this.language,
      ),
      sorts: this.moduleSelectionService.moduleSelectionsModuleTypeGet(
        this.blockData.module,
        0,
        10,
        'SORT',
        undefined,
        this.language,
      ),
      module: this.moduleDataService.moduleDataModuleGet(this.blockData.module),
    }).subscribe({
      next: (results) => {
        const { filters, sorts, module } = results;
        const filtersData = filters.data?.data;

        const sortsData = sorts.data?.data;
        if (filtersData) {
          this.filtersBy = filtersData.filter(
            (item) => !!item.selection_display_type,
          );
        }
        if (sortsData) {
          this.sortsBy = sortsData.map((item) => ({
            name: item.name || '',
            value: item.filter_keyword + (item.filter_column || '') || '',
            scope: ['double', 'price', 'star'].some(
              (kw) => item.filter_keyword?.toLocaleLowerCase()?.includes(kw),
            )
              ? 'number'
              : 'string',
            disabled: false,
          }));
        }
        if (module) {
          this.moduleData = module.data;

          this.addFilterRequired(this.moduleData?.required);
        }

        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  onLineChange(event: InputNumberInputEvent) {
    const inputValue = +event.value;
    if (inputValue < 1) {
      this.blockData.line = 1;
    } else if (inputValue > 5) {
      this.blockData.line = 5;
    }
  }

  onItemPerLineChange(event: InputNumberInputEvent) {
    const inputValue = +event.value;
    if (inputValue < 1) {
      this.blockData.itemPerLine = 1;
    } else if (inputValue > 5) {
      this.blockData.itemPerLine = 5;
    }
  }

  // FILTERS
  onToggleFilter() {
    this.isModalFilter = true;
  }

  onAddFilter(filter: ModuleSelection) {
    if (!filter.selection_display_type || this.isDisableFilter(filter.code))
      return;

    this.isModalFilter = false;
    let value = undefined;
    switch (filter.selection_display_type) {
      case PRODUCT_LIST_FILTER.RANGE: {
        value = {
          from: undefined,
          to: undefined,
        };
        break;
      }
      case PRODUCT_LIST_FILTER.RATING: {
        value = undefined;
        break;
      }
      default: {
        value = [];
      }
    }
    this.blockData.filters = [
      ...this.blockData.filters,
      {
        ...filter,
        value,
      } as BlockFilter,
    ];

    const filterCodesOfBlock = this.blockData.filters.map((item) => item.code);
    const filterRequired = filter.required?.filter(
      (item) => !filterCodesOfBlock.includes(item.selection_code),
    );
    this.addFilterRequired(filterRequired, filter.code);
  }

  addFilterRequired(data?: RequiredSelectionDTO[], code?: string) {
    if (data && data?.length) {
      for (let required of data) {
        const filterRequired = this.filtersBy.find(
          (item) => item.code === required.selection_code,
        );

        const filterValue = this.blockData.filters.find(
          (item) => item.code === code,
        )?.value;

        if (
          filterRequired &&
          ((!required.selection_field && !required.selection_value) ||
            (Array.isArray(filterValue)
              ? filterValue?.some(
                  (item: any) =>
                    item[required.selection_field || ''] ===
                    required.selection_value,
                )
              : filterValue?.[required.selection_field || ''] ===
                required.selection_value))
        ) {
          this.onAddFilter(filterRequired);
        }
      }
    }
  }

  isDisableFilter(code?: string) {
    if (!code) return false;
    return this.blockData.filters.some((filter) => filter.code === code);
  }

  onDeleteProduct(itemId: string) {
    if (this.blockData.specific_products) {
      this.blockData.specific_products = [
        ...this.blockData.specific_products,
      ].filter((product) => product.id !== itemId);
    }
  }

  // SORTS
  onToggleAddSort() {
    this.isModalSort = true;
  }

  onAddSort(sort: string, scope: string) {
    if (this.isDisableSort(sort)) return;

    this.isModalSort = false;
    const value = SORT_ITEMS.find((item) => item.scope === scope)?.value || 1;
    this.blockData.sorts.push({
      key: sort,
      value: value,
    });
  }

  onDeleteSort(key: string) {
    const sorts = this.blockData.sorts.filter((item) => item.key !== key);
    this.blockData.sorts = [...sorts];
  }

  onMoveUpSort(index: number) {
    const temp = this.blockData.sorts[index];

    this.blockData.sorts[index] = { ...this.blockData.sorts[index - 1] };
    this.blockData.sorts[index - 1] = { ...temp };
  }

  onMoveDownSort(index: number) {
    const temp = this.blockData.sorts[index];

    this.blockData.sorts[index] = { ...this.blockData.sorts[index + 1] };
    this.blockData.sorts[index + 1] = { ...temp };
  }

  getSortValueByKey(key: string) {
    const scope = this.sortsBy.find((item) => item.value === key)?.scope;
    return SORT_ITEMS.filter((item) => item.scope === scope);
  }

  isDisabledSortKey(key: string) {
    return this.blockData.sorts.some((sort) => sort.key === key);
  }

  isDisableSort(sortValue: string) {
    return this.blockData.sorts.some((item) => item.key === sortValue);
  }

  onCountProduct() {
    const layerIndex = this.elementSelected.layerIndex;
    const blockIndex = this.elementSelected.blockIndex;

    const {
      sorts,

      specific_products,
      isSpecificProduct,

      filters,
    } = this.blockData;

    const productFilters: string[] = [];
    const productSorts: string[] = [];

    if (isSpecificProduct && specific_products?.length) {
      productFilters.push(
        `id==${specific_products.map((product) => product.id).join('|')}`,
      );
    }

    if (sorts?.length) {
      sorts.forEach((item) => {
        productSorts.push(`${item.value > 0 ? '' : '-'}${item.key}`);
      });
    }

    const sortsString = encodeURIComponent(productSorts.join(','));

    const filtersString = encodeURIComponent(
      getProductFilters(filters, isSpecificProduct, specific_products),
    );

    this.isCounting = true;
    this.marketingHubService
      .getModuleData(
        this.blockData.module,
        filtersString,
        undefined,
        25,
        sortsString,
      )
      .subscribe({
        next: (res) => {
          this.isCounting = false;
          const data = res.data?.data;

          this.layers[layerIndex].blocks[blockIndex].totalProducts =
            data?.totalElement;
          this.layers[layerIndex].blocks[blockIndex].products = data?.data;
        },
        error: () => {
          this.isCounting = false;
        },
      });
  }

  onFilterTypeChange(value: FILTER_TAB_ENUM) {
    this.blockData.isSpecificProduct =
      value === FILTER_TAB_ENUM.SPECIFIC_PRODUCTS;
  }

  onChangeModule() {
    this.fetchModuleFilters();
    this.blockData.filters = [];

    this.blockData.specific_products = [];
    this.blockData.products = [];
    this.blockData.totalProducts = 0;
    this.blockData.sorts = [];
    this.blockData.isSpecificProduct = false;

    this.onCountProduct();
  }

  onChangeData(filter?: BlockFilter) {
    const filterData = this.filtersBy.find(
      (item) => item.code === filter?.code,
    );
    this.addFilterRequired(filterData?.required, filterData?.code);
  }

  getDataAgency(product: BlockEntityData) {
    switch (this.blockData.module) {
      case 'SHOP': {
        return {
          avatar: product?.['avatar']?.id,
          name: product?.['name'],
        };
      }

      case 'TRAVEL_TOUR': {
        return {
          avatar: product?.['avatar'],
          name: product?.['title'],
        };
      }

      default: {
        return { avatar: '', name: '' };
      }
    }
  }
}
