import * as moment from 'moment';
import { PRODUCT_LIST_FILTER } from 'src/app/core/enum/marketing-builder';
import {
  BlockEntityData,
  BlockFilter,
} from 'src/app/core/models/interfaces/marketing-builder';

export function getProductFilters(
  filters: BlockFilter[],
  isSpecificProduct: boolean,
  specific_products?: BlockEntityData[],
) {
  const productFilters: string[] = [];

  if (isSpecificProduct && specific_products?.length) {
    productFilters.push(
      `id@==${specific_products.map((product) => product.id).join('|')}`,
    );
  }

  return !isSpecificProduct
    ? filters
        .map((filter) => {
          if (!filter.value) return null;

          switch (filter.selection_display_type) {
            case PRODUCT_LIST_FILTER.RATING: {
              return `${filter.filter_keyword}${filter.value}`;
            }

            case PRODUCT_LIST_FILTER.YYYYMMDD: {
              return `${filter.filter_keyword}${moment(filter.value).format(
                'yyyy-MM-DD',
              )}`;
            }

            case PRODUCT_LIST_FILTER.RANGE: {
              const keywords = filter.filter_keyword?.split(',');
              const from = filter.value?.from;
              const to = filter.value?.to;

              if (keywords && from && to) {
                const [keyword1, keyword2] = keywords;
                if (filter.selection_choice_type === 'MILLISECONDS') {
                  return `${keyword1}${new Date(
                    from,
                  ).getTime()},${keyword2}${new Date(to).getTime()}`;
                }

                return `${keyword1}${from},${keyword2}${to}`;
              } else {
                return null;
              }
            }

            default: {
              if (Array.isArray(filter.value)) {
                if (filter.value.length === 0) return null;

                const keywords = filter.filter_keyword?.split(',');
                const columns = filter.filter_column?.split(',');

                if (
                  keywords?.length &&
                  keywords?.length > 1 &&
                  columns?.length &&
                  columns?.length > 1 &&
                  keywords?.length === columns?.length
                ) {
                  const keywords = filter.filter_keyword?.split(',') || [];

                  return filter.value?.map((item: any) =>
                    keywords
                      .map(
                        (keyword, index) =>
                          `${keyword}${item?.[columns[index]]}`,
                      )
                      .join(','),
                  );
                } else {
                  return `${filter.filter_keyword}${filter.value
                    ?.map((item: any) =>
                      filter.filter_column
                        ? item?.[filter.filter_column]
                        : item,
                    )
                    ?.join('|')}`;
                }
              } else {
                return `${filter.filter_keyword}${
                  filter.filter_column
                    ? filter.value?.[filter.filter_column]
                    : filter.value
                }`;
              }
            }
          }
        })
        ?.filter((item) => item !== null)
        ?.join(',')
    : productFilters.join('|');
}
