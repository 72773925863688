<p-dialog
  appendTo="body"
  [modal]="true"
  [draggable]="false"
  [(visible)]="isModal"
  (onHide)="setIsModal(false)"
  [header]="'landing-page-builder.select-product' | translate"
  [style]="{ 'min-width': '860px', width: '60vw' }"
  contentStyleClass="!pb-1"
>
  <div
    *ngIf="module === 'SHOP'"
    class="flex gap-4 items-center justify-between py-1 pb-2"
  >
    <span class="p-input-icon-left w-2/5">
      <i class="pi pi-search"></i>
      <input
        [(ngModel)]="keyword"
        (ngModelChange)="keywordChanged.next($event)"
        class="h-9 !text-sm w-full rounded-lg"
        type="text"
        pInputText
        [placeholder]="'common.search-by-product-name' | translate"
      />
    </span>

    <div class="flex-1 flex items-center gap-2">
      <div class="flex-1">
        <p-dropdown
          appendTo="body"
          [(ngModel)]="rating"
          styleClass="h-9 items-center w-full"
          [placeholder]="'common.product-rating' | translate"
          [options]="RATINGS"
          (onChange)="_fetchProducts(true)"
        >
          <ng-template pTemplate="selectedItem">
            <p-rating
              *ngIf="rating"
              [ngModel]="rating"
              [cancel]="false"
              [readonly]="true"
            ></p-rating>
          </ng-template>

          <ng-template pTemplate="item" let-star>
            <p-rating
              [ngModel]="star"
              [cancel]="false"
              [readonly]="true"
            ></p-rating>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="flex-1">
        <p-treeSelect
          [metaKeySelection]="false"
          [(ngModel)]="categories"
          [ngModelOptions]="{ standalone: true }"
          [options]="_categories"
          placeholder="All categories"
          labelStyleClass="h-9 !flex items-center"
          containerStyleClass="w-full flex h-9"
          selectionMode="multiple"
          display="chip"
          (ngModelChange)="_fetchProducts(true)"
        >
          <ng-template pTemplate="value">
            <div *ngIf="categories.length == 0">
              {{ "common.all-categories" | translate | sentenceCase }}
            </div>
            <div>
              <ul class="flex flex-wrap gap-2">
                <li
                  class="flex items-center justify-center px-3 py-1 rounded-full gap-2 bg-gray-200"
                  *ngFor="let category of categories; let i = index"
                >
                  <span>{{ category.label }}</span>
                  <span
                    (click)="onRemoveCategory($event, i)"
                    [class]="SoctripIcons.X_CLOSE"
                  ></span>
                </li>
              </ul>
            </div>
          </ng-template>
        </p-treeSelect>
      </div>
    </div>
  </div>

  <div class="border rounded-lg overflow-hidden relative">
    <p-table
      dataKey="id"
      scrollHeight="calc(100vh - 410px)"
      [scrollable]="true"
      [style]="{ 'min-height': '200px' }"
      [loading]="isFetching"
      [selectionPageOnly]="true"
      [selection]="selectedProducts"
      [value]="_products"
      [columns]="columns"
      [tableStyle]="{ width: '100%' }"
      (selectionChange)="onChangeSelectedProducts($event)"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 4rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th *ngFor="let col of columns">
            {{ col.name }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>
            <p-tableCheckbox [value]="product"></p-tableCheckbox>
          </td>
          <td *ngFor="let col of columns">
            <span [ngSwitch]="col.type">
              <ng-container *ngSwitchCase="'PRICE'">{{
                product[col.field_name] | currency
              }}</ng-container>
              <ng-container *ngSwitchCase="'STRING'">{{
                getValueWithOptionalChaining(product, col.field_name)
              }}</ng-container>
              <span class="flex items-center gap-1" *ngSwitchCase="'RATING'"
                >{{ product[col.field_name]
                }}<p-rating
                  [stars]="1"
                  [ngModel]="1"
                  [readonly]="true"
                  [cancel]="false"
                ></p-rating>
              </span>

              <ng-container *ngSwitchCase="'IMAGE'">
                <app-custom-image
                  styleClass="w-10 h-10 rounded-md overflow-hidden"
                  [imgId]="
                    getValueWithOptionalChaining(product, col.field_name)
                  "
                  alt="Thumbnail"
                ></app-custom-image>
              </ng-container>
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div
          *ngIf="_products.length === 0 && isFetched"
          class="bg-white w-full flex flex-col items-center py-4"
        >
          <img
            class="h-[60px]"
            src="assets/imgs/empty-data.svg"
            [alt]="'common.empty-data' | translate | sentenceCase"
          />
          <span class="text-gray-300 mt-2 text-xs">
            {{ "common.no-data" | translate | sentenceCase }}
          </span>
        </div>
      </ng-template>
    </p-table>
  </div>
  <ng-template pTemplate="footer">
    <div>
      <app-paginator
        *ngIf="module !== 'FLIGHT'"
        (onChange)="_fetchProducts(false)"
        [(pagination)]="pagination"
        [totalRecords]="_totalProduct"
        [dataLength]="_products.length"
        [stylesClass]="'!p-0'"
      ></app-paginator>

      <div
        [ngClass]="{ 'pt-4': _totalProduct <= 5 }"
        class="w-full flex justify-end gap-1"
      >
        <app-button
          [label]="'section-action.cancel' | translate | sentenceCase"
          variant="outline-secondary"
          size="lg"
          (onClick)="onCancelAddProduct()"
        ></app-button>
        <app-button
          [label]="'section-action.save' | translate | sentenceCase"
          size="lg"
          (onClick)="onAddProducts()"
        ></app-button>
      </div>
    </div>
  </ng-template>
</p-dialog>
