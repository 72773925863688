<p-dialog
  appendTo="body"
  [modal]="true"
  [draggable]="false"
  [(visible)]="isModal"
  (onHide)="setIsModal(false)"
  [header]="'landing-page-builder.add-voucher' | translate"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [style]="{ 'min-width': '860px' }"
  contentStyleClass="!pb-1"
  (onShow)="_fetchVouchers(true)"
>
  <!-- <div class="flex gap-2">
    <div class="pb-4 w-full flex flex-col gap-2">
      <label class="text-sm">
        {{ "common.categories" | translate | sentenceCase }}
        <span class="text-orange-dark-500 text-sm">*</span>
      </label>
      <p-treeSelect
        [metaKeySelection]="false"
        [(ngModel)]="categories"
        [ngModelOptions]="{ standalone: true }"
        [options]="_categories"
        placeholder="All categories"
        containerStyleClass="w-full"
        selectionMode="multiple"
        display="chip"
        (ngModelChange)="_fetchProducts(true)"
      >
        <ng-template pTemplate="value">
          <div *ngIf="categories.length == 0">All categories</div>
          <div>
            <ul class="flex flex-wrap gap-2">
              <li
                class="flex items-center justify-center px-3 py-1 rounded-full gap-2 bg-gray-200"
                *ngFor="let category of categories; let i = index"
              >
                <span>{{ category.label }}</span>
                <span
                  (click)="onRemoveCategory($event, i)"
                  [class]="SoctripIcons.X_CLOSE"
                ></span>
              </li>
            </ul>
          </div>
        </ng-template>
      </p-treeSelect>
    </div>
  </div> -->

  <div class="flex items-center justify-between gap-3">
    <span class="text-sm">
      {{ "landing-page-builder.add-up-to" | translate }}
      {{ (selectedVouchers || []).length }}/{{ max }}
      {{ "landing-page-builder.voucher" | translate | lowercase }}
    </span>

    <div class="p-input-icon-left w-2/5 mb-3 mt-1">
      <i class="pi pi-search"></i>
      <input
        [(ngModel)]="keyword"
        (ngModelChange)="keywordChange$.next($event)"
        class="h-9 !text-sm w-full rounded-lg"
        type="text"
        pInputText
        [placeholder]="
          'landing-page-builder.search-by-voucher-name-or-code' | translate
        "
      />
    </div>
  </div>

  <div class="border rounded-lg overflow-hidden relative">
    <div
      *ngIf="isFetching"
      class="absolute z-10 flex top-0 right-0 bottom-0 left-0 items-center justify-center bg-gray-200/50"
    ></div>

    <p-table
      dataKey="id"
      scrollHeight="calc(100vh - 410px)"
      [scrollable]="true"
      [selectionPageOnly]="true"
      [selection]="selectedVouchers"
      [value]="_vouchers"
      [tableStyle]="{ width: '100%' }"
      (selectionChange)="selectedVouchers = $event"
    >
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th>
            {{ "landing-page-builder.voucher-name" | translate }}
          </th>
          <th>
            {{ "landing-page-builder.discount" | translate }}
          </th>
          <th>
            {{ "landing-page-builder.valid-period" | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-voucher>
        <tr
          [ngClass]="{
            'disabled-row': isRowDisabled(voucher)
          }"
        >
          <td>
            <p-tableCheckbox [value]="voucher"></p-tableCheckbox>
          </td>
          <td>
            <div class="flex items-center gap-2">
              <app-custom-image
                class="min-w-[40px] w-10 h-10 overflow-hidden rounded-full"
                [imgId]="voucher?.avatar_id"
                [alt]="voucher?.name"
              >
              </app-custom-image>
              <div class="flex flex-col gap-0.5">
                <span class="line-clamp-2 max-w-[400px]">
                  {{ voucher.name }}
                </span>
              </div>
            </div>
          </td>
          <td>{{ formatDiscount(voucher) }}</td>
          <td>
            {{ voucher?.start_date | dateFormat }} -
            {{ voucher?.end_date | dateFormat }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div
          *ngIf="_vouchers.length === 0 && isFetched"
          class="bg-white w-full flex flex-col items-center py-4"
        >
          <img
            class="h-[60px]"
            src="assets/imgs/empty-data.svg"
            [alt]="'common.empty-data' | translate | sentenceCase"
          />
          <span class="text-gray-300 mt-2 text-xs">
            {{ "common.no-data" | translate | sentenceCase }}
          </span>
        </div>
      </ng-template>
    </p-table>
  </div>
  <ng-template pTemplate="footer">
    <app-paginator
      (onChange)="_fetchVouchers(false)"
      [(pagination)]="pagination"
      [totalRecords]="_totalVouchers"
      [dataLength]="_vouchers.length"
      [stylesClass]="'!p-0'"
    ></app-paginator>

    <div
      [ngClass]="{ 'pt-4': _totalVouchers <= 5 }"
      class="w-full flex justify-end gap-1"
    >
      <app-button
        [label]="'section-action.cancel' | translate | sentenceCase"
        variant="outline-secondary"
        size="lg"
        (onClick)="onCancelAddProduct()"
      ></app-button>
      <app-button
        [label]="'section-action.save' | translate | sentenceCase"
        size="lg"
        (onClick)="onAddProducts()"
      ></app-button>
    </div>
  </ng-template>
</p-dialog>
