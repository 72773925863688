import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  BlockModuleDataDTO,
  CampaignBlockDTO,
  CampaignLayerRequestDTO,
  MarketingCampaignControllerService,
  MarketingCampaignRequestDTO,
} from '@soctrip/angular-marketing-hub-service';
import { MessageService } from 'primeng/api';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import {
  PREVIEW_DEVICE,
  PRODUCT_LIST_FILTER,
} from 'src/app/core/enum/marketing-builder';
import { Layer } from 'src/app/core/models/interfaces/marketing-builder';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { environment } from 'src/environments/environment';

export interface MarketingData {
  name: string;
  language: string;
  module: MarketingCampaignRequestDTO.ModuleTypeEnum;
  updatedAt: Date;
  link: string;
}

@Component({
  selector: 'app-header-builder',
  templateUrl: './header-builder.component.html',
  styleUrls: ['./header-builder.component.scss'],
})
export class HeaderBuilderComponent {
  @Input({ required: true }) campaignId: string = '';
  @Input({ required: true }) marketing: MarketingData = {
    name: '',
    language: 'vn',
    module: 'SHOP',
    updatedAt: new Date(),
    link: '',
  };

  @Input() mode: string = 'BUILDER';
  @Input() layers: Layer[] = [];
  @Input() previewDevice: string = PREVIEW_DEVICE.DESKTOP;

  @Output() modeChange = new EventEmitter<string>();
  @Output() previewDeviceChange = new EventEmitter<string>();
  @Output() marketingChange = new EventEmitter<MarketingData>();

  readonly builderPrefix = 'landing-page-builder.';
  readonly SoctripIcons = SoctripIcons;
  readonly DEVICES = [
    {
      title: 'Desktop',
      value: 'DESKTOP',
      icon: SoctripIcons.MONITOR_03,
    },
    {
      title: 'Mobile',
      value: 'MOBILE',
      icon: SoctripIcons.PHONE_02,
    },
  ];

  languages = [
    {
      name: 'Vietnam',
      code: 'vn',
    },
    {
      name: 'US',
      code: 'us',
    },
  ];

  modules: Array<{
    name: string;
    value: string;
  }> = [];

  isUpdating: boolean = false;
  isSubmit: boolean = false;

  constructor(
    private router: Router,
    private marketingCampaignService: MarketingCampaignControllerService,
    private messageService: MessageService,
    private translator: CustomTranslateService,
    private http: HttpClient,
  ) {}

  get isBuilder() {
    return this.mode === 'BUILDER';
  }

  get isPreview() {
    return this.mode === 'PREVIEW';
  }

  get isValidName() {
    return this.marketing.name.trim();
  }

  ngOnInit() {
    this.getAllModules();
  }

  onCheckValidName(): string {
    if (this.isSubmit) {
      if (!this.isValidName) {
        return 'ng-invalid-field';
      }
    }

    return '';
  }

  onMarketingNameChange(event: string) {
    this.marketing.name = event;
    this.marketingChange.emit(this.marketing);
  }

  onLanguageChange(language: string) {
    this.marketing.language = language;
    this.marketingChange.emit(this.marketing);
  }

  onModuleChange(module: MarketingCampaignRequestDTO.ModuleTypeEnum) {
    this.marketing.module = module;
    this.marketingChange.emit(this.marketing);
  }

  onChangeDevice(event: string) {
    this.previewDevice = event;
    this.previewDeviceChange.emit(this.previewDevice);
  }

  onPreview() {
    this.mode = 'PREVIEW';
    this.modeChange.emit(this.mode);
  }

  onBack() {
    if (this.mode === 'BUILDER') {
      this.router.navigate(['landing-page']);
    } else {
      this.mode = 'BUILDER';
      this.modeChange.emit(this.mode);
    }
  }

  onCopyLink(link: string) {
    navigator.clipboard.writeText(link).then(
      () => {
        this.messageService.add({
          severity: 'success',
          detail: this.translator.sentenceCase('landing-page.copy-landing'),
        });
      },
      (err) => {
        console.error('Failed to copy text: ', err);
      },
    );
  }

  getMarketingCampaignBody(isActive: boolean) {
    const body: MarketingCampaignRequestDTO = {
      title: this.marketing.name,
      country_code: this.marketing.language,
      is_active: isActive,
      reference_id: [],
      layers: [],
      module_type: this.marketing.module,
    };

    this.layers.forEach((layer, layerIndex) => {
      body.reference_id?.push(layer.id);
      const layerConvert: CampaignLayerRequestDTO = {
        id: layer.id,
        title: layer.name,
        description: layer.description,
        order: layerIndex,
        code: 'ONE_ONE',
        is_new: layer.isNew,
        blocks: [],
        color: layer.textColor,
        background_color: layer.bgColor,
      };

      layer.blocks.forEach((block, blockIndex) => {
        const productSorts: string[] = [];

        const { specific_products, sorts, isSpecificProduct } = block;

        let moduleData: BlockModuleDataDTO[] = [];
        // SPECIFIC PRODUCTS
        if (isSpecificProduct) {
          if (specific_products?.length) {
            moduleData = [
              {
                selection_display_type: 'SPECIFIC',
                filter: JSON.stringify(specific_products),
                name: '',
                code: '',
                url: '',
                filter_column: '',
                table_filter_keywords: '',
                selection_type: 'FILTER',
                module: 'SHOP',
              },
            ];
          }
        } else {
          moduleData = [
            ...block.filters
              .filter((item) =>
                Array.isArray(item.value)
                  ? item.value.length > 0
                  : !!item.value,
              )
              .filter((item) => item.selection_display_type !== 'SPECIFIC')
              .map((item) => {
                return {
                  ...item,
                  filter: JSON.stringify(item.value),
                };
              }),
          ];
        }

        if (sorts?.length) {
          sorts.forEach((item) => {
            productSorts.push(`${item.value > 0 ? '' : '-'}${item.key}`);
          });
        }

        const blockConvert: CampaignBlockDTO = {
          code: block.type,
          text: block.text,
          description: block.description,
          desktop_row: block.flex,
          order: blockIndex,
          line: block.line,
          desktop_items_per_line: block.itemPerLine,
          media: block.medias.map((media) => ({
            // Desktop
            media_desktop_id: media.media1?.id,
            desktop_name: media.media1?.name,
            desktop_size: media.media1?.size,
            desktop_link: media.link1,

            // Mobile
            media_mobile_id: media.media2?.id,
            mobile_name: media.media2?.name,
            mobile_size: media.media2?.size,
            mobile_link: media.link2,

            is_open_in_new_tab: media.isOpenNewTab,
            reference_id: media.reference_id,
          })),
          module: (block.module as CampaignBlockDTO.ModuleEnum) || 'SHOP',
          sort: productSorts.join(','),
          objects: block.vouchers
            ? block.vouchers
                ?.slice(0, block.line * block.itemPerLine)
                .map((voucher) => ({
                  object_id: voucher.id,
                  object_type: 'string',
                }))
            : undefined,
          module_data_list: moduleData,
          alignment: block.alignment,
          animation_custom: block.animation_custom,
          curve: block.curve,
          duration: block.duration,
          position: block.isStick ? 'STICKY' : 'SCROLL_WITH_PARENT',
        };

        if (layerConvert.blocks) {
          layerConvert.blocks.push(blockConvert);
        }
      });

      body.layers?.push(layerConvert);
    });

    return body;
  }

  validateDescriptionLength(layers: Layer[], maxLength: number = 120): boolean {
    return !layers.some((layer) => {
      if (layer.description && layer.description.length > maxLength) {
        return true;
      }
      return layer.blocks?.some((block) => {
        if (block.description && block.description.length > maxLength) {
          return true;
        }
        return false;
      });
    });
  }

  onSave(isActive: boolean) {
    this.isSubmit = true;

    if (!this.isValidName) {
      this.messageService.add({
        severity: 'error',
        detail: this.translator.transform(
          this.builderPrefix + 'please-enter-marketing-name',
        ),
      });
      return;
    }

    if (!this.validateDescriptionLength(this.layers)) {
      return;
    }
    const body: MarketingCampaignRequestDTO =
      this.getMarketingCampaignBody(isActive);

    if (body.layers?.some((item) => (item.title?.length ?? 0) > 120)) {
      this.messageService.add({
        severity: 'error',
        detail: this.translator.transform(
          this.builderPrefix + 'max-length-title',
        ),
      });
      return;
    }
    this.isUpdating = true;
    if (this.campaignId && this.campaignId !== 'new') {
      this.marketingCampaignService
        .marketingCampaignsIdPut(body, this.campaignId)
        .subscribe({
          next: (res) => {
            this.messageService.add({
              severity: 'success',
              detail: this.translator.transform(
                this.builderPrefix + 'update-marketing-campaign-successfully',
              ),
            });

            this.isUpdating = false;

            this.marketing.updatedAt = res.data?.updated_at || new Date();
            this.marketingChange.emit(this.marketing);

            this.navigateToManagement();
          },
          error: (error) => {
            this.messageService.add({
              severity: 'error',
              detail:
                error?.error?.error?.message ||
                this.translator.transform(
                  this.builderPrefix + 'update-marketing-campaign-failed',
                ),
            });

            this.isUpdating = false;
          },
        });
    } else {
      this.marketingCampaignService.marketingCampaignsPost(body).subscribe({
        next: (res) => {
          this.messageService.add({
            severity: 'success',
            detail: this.translator.transform(
              this.builderPrefix + 'create-marketing-campaign-successfully',
            ),
          });

          this.isUpdating = false;

          this.marketing.updatedAt = res.data?.updated_at || new Date();
          this.marketingChange.emit(this.marketing);

          this.navigateToManagement();
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            detail:
              error?.error?.error?.message ||
              this.translator.transform(
                this.builderPrefix + 'create-marketing-campaign-failed',
              ),
          });

          this.isUpdating = false;
        },
      });
    }
  }

  navigateToManagement() {
    this.router.navigateByUrl('/landing-page');
  }

  getAllModules() {
    // this.http
    //   .get(`${environment.BE_URL + 'voucher-hub'}/vouchers/modules`)
    //   .subscribe({
    //     next: (res: any) => {
    //       this.modules = res?.data.map((item: string) => ({
    //         name: item
    //           .replace(/_/g, ' ')
    //           .toLowerCase()
    //           .replace(/\b\w/g, (char: string) => char.toUpperCase()),
    //         value: item,
    //       }));
    //     },
    //   });
    this.modules = [
      {
        name: 'All',
        value: 'ALL',
      },
      {
        name: 'Social',
        value: 'SOCIAL',
      },
      {
        name: 'Shop',
        value: 'SHOP',
      },
      {
        name: 'Restaurant',
        value: 'RESTAURANT',
      },
      {
        name: 'Hotel',
        value: 'HOTEL',
      },
      {
        name: 'Travel tour',
        value: 'TRAVEL_TOUR',
      },
      {
        name: 'Car rental',
        value: 'CAR',
      },
      {
        name: 'Flight',
        value: 'FLIGHT',
      },
    ];
  }
}
