<div class="py-8">
  <div
    class="mx-auto bg-white"
    [ngClass]="isDesktop ? 'max-w-[1440px]' : 'max-w-[390px]'"
  >
    <app-soctrip-header [previewDevice]="previewDevice"></app-soctrip-header>

    <body>
      <div *ngFor="let layer of data" [style.backgroundColor]="layer.bgColor">
        <div
          class="flex flex-col mx-auto"
          [ngClass]="
            isDesktop ? 'py-6 gap-4 max-w-[960px]' : 'p-2 gap-2 max-w-[390px]'
          "
        >
          <h1
            *ngIf="layer.name"
            class="text-xl font-semibold"
            [style.color]="layer.textColor"
          >
            {{ layer.name }}
          </h1>

          <p-editor
            *ngIf="layer.description"
            styleClass="editor-preview"
            [style.backgroundColor]="layer.bgColor"
            [style.color]="layer.textColor"
            [readonly]="true"
            [ngModel]="layer.description"
          ></p-editor>

          <div class="flex items-center" [ngClass]="isDesktop ? 'gap-4' : 'gap-2'">
            <div *ngFor="let block of layer.blocks" [style.flex]="block.flex">
              <div [ngSwitch]="block.type">
                <!-- TEXT -->
                <div *ngSwitchCase="BLOCK_TYPE.TEXT">
                  <p-editor
                    styleClass="editor-preview"
                    [readonly]="true"
                    [ngModel]="block.text"
                  ></p-editor>
                </div>

                <!-- IMAGE -->
                <div class="text-sm" *ngSwitchCase="BLOCK_TYPE.IMAGE">
                  <app-custom-image
                    *ngIf="block.medias[0]"
                    [imgId]="
                      (isDesktop
                        ? block.medias[0].media1?.id
                        : block.medias[0].media2?.id) || ''
                    "
                    imageClass="w-[unset]"
                    alt="Image"
                  ></app-custom-image>
                </div>

                <!-- VIDEO -->
                <div class="text-sm" *ngSwitchCase="BLOCK_TYPE.VIDEO">
                  <video
                    *ngIf="block.medias[0].media1?.id"
                    controls
                    class="w-full max-h-full"
                  >
                    <source
                      [src]="
                        fileService.getVideoSrc(
                          block.medias[0].media1?.id || ''
                        )
                      "
                      type="video/mp4"
                    />
                  </video>

                  <iframe
                    *ngIf="!block.medias[0].media1?.id && block.medias[0].link2"
                    style="width: 100%; aspect-ratio: 2 / 1"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                    frameborder="0"
                    allowfullscreen
                    [src]="convertToIframeLink(block.medias[0].link2)"
                  ></iframe>
                </div>

                <!-- SLIDE -->
                <div *ngSwitchCase="BLOCK_TYPE.SLIDE">
                  <p-galleria
                    *ngIf="block.medias.length > 0"
                    [circular]="true"
                    [transitionInterval]="2000"
                    [value]="block.medias"
                    [showItemNavigators]="true"
                    [showThumbnails]="false"
                    [showIndicators]="true"
                    [autoPlay]="true"
                    [indicatorsPosition]="'bottom'"
                  >
                    <ng-template pTemplate="itempreviousicon">
                      <div
                        class="bg-black/40 h-11 w-11 flex items-center justify-center rounded-full cursor-pointer hover:bg-black/60"
                      >
                        <i
                          class="{{
                            SoctripIcons.CHEVRON_LEFT
                          }} text-white text-2xl"
                        ></i>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="itemnexticon">
                      <div
                        class="bg-black/40 h-11 w-11 flex items-center justify-center rounded-full cursor-pointer hover:bg-black/60"
                      >
                        <i
                          class="{{
                            SoctripIcons.CHEVRON_RIGHT
                          }} text-white text-2xl"
                        ></i>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="item" let-item>
                      <app-custom-image
                        class="w-full h-[240px] object-cover"
                        [imgId]="isDesktop ? item.media1?.id : item.media2?.id"
                        alt="Slide"
                      />
                    </ng-template>
                  </p-galleria>
                </div>

                <!-- TAB -->
                <div
                  *ngSwitchCase="BLOCK_TYPE.TAB"
                  [ngClass]="isDesktop ? 'max-w-[960px]' : 'max-w-[375px]'"
                >
                  <swiper-container
                    class="mySwiper w-full"
                    space-between="4"
                    slides-per-view="auto"
                    [navigation]="true"
                  >
                    <swiper-slide
                      *ngFor="let media of block.medias"
                      class="h-36 w-36 flex items-center"
                    >
                      <app-custom-image
                        [imgId]="media.media1?.id || ''"
                        alt="Tab"
                      ></app-custom-image>
                    </swiper-slide>
                  </swiper-container>
                </div>

                <!-- PRODUCT LIST -->
                <div *ngSwitchCase="BLOCK_TYPE.PRODUCT_LIST">
                  <div
                    class="grid gap-4 rounded-lg"
                    [ngStyle]="{
                      'grid-template-rows': getGridTemplate(block.line),
                      'grid-template-columns': isDesktop
                        ? getGridTemplate(block.itemPerLine)
                        : '1fr 1fr'
                    }"
                  >
                    <app-product-card
                      *ngFor="
                        let item of generateArray(
                          block.itemPerLine * block.line
                        );
                        let i = index
                      "
                      [product]="block.products?.[i]"
                      [module]="block.module"
                    ></app-product-card>
                  </div>
                </div>

                <!-- VOUCHER -->
                <div *ngSwitchCase="BLOCK_TYPE.VOUCHER">
                  <div
                    class="grid gap-4 rounded-lg"
                    [ngStyle]="{
                      'grid-template-rows': getGridTemplate(block.line),
                      'grid-template-columns': isDesktop
                        ? getGridTemplate(block.itemPerLine)
                        : '1fr'
                    }"
                  >
                    <div
                      *ngFor="
                        let item of generateArray(
                          block.line * block.itemPerLine
                        );
                        let i = index
                      "
                      class="rounded-lg border overflow-hidden h-28"
                    >
                      <!-- Voucher -->
                      <div
                        *ngIf="
                          block.vouchers && block.vouchers[i];
                          else voucherDefaultTemplate
                        "
                        class="bg-white shadow-md h-full w-full rounded-lg flex"
                      >
                        <div
                          class="flex items-center justify-center h-full w-[120px] border-r border-dashed relative"
                        >
                          <div
                            class="w-4 h-4 absolute rounded-full bg-gray-100 -right-2 top-0 -translate-y-1/2"
                          ></div>
                          <div
                            class="w-4 h-4 absolute rounded-full bg-gray-100 right-[-8px] bottom-0 translate-y-1/2"
                          ></div>
                          <!-- Avatar -->
                          <div
                            class="flex items-center justify-center h-16 w-16 rounded-md p-2 bg-opacity-50"
                          >
                            <app-custom-image
                              class="w-full h-full"
                              [imgId]="block.vouchers[i].avatar_id"
                              [alt]="block.vouchers[i].name"
                            />
                          </div>
                        </div>
                        <div class="flex gap-1 flex-1 px-2 py-4">
                          <div
                            class="flex-1 flex flex-col gap-1 justify-center"
                          >
                            <span class="font-semibold text-lg"
                              >{{
                                "landing-page-builder.discount" | translate
                              }}
                              10%</span
                            >
                            <span class="text-sm"
                              >{{
                                "landing-page-builder.min-spend" | translate
                              }}
                              $9.00</span
                            >
                            <span class="text-sm text-gray-500"
                              >{{
                                "landing-page-builder.valid-through" | translate
                              }}
                              {{
                                block.vouchers[i].end_date
                                  | date: "HH:mm, MM/dd/yyyy"
                              }}</span
                            >
                          </div>
                          <div
                            class="text-sm font-medium h-fit py-2 px-4 rounded-lg bg-orange-dark-500 text-white"
                          >
                            {{ "section-action.view" | translate }}
                          </div>
                        </div>
                      </div>

                      <ng-template #voucherDefaultTemplate>
                        <div class="bg-white flex">
                          <div
                            class="h-28 w-28 flex items-center justify-center bg-gray-50"
                          >
                            <img
                              src="/assets/icons/voucher.svg"
                              alt="Voucher Icon"
                            />
                          </div>
                          <div class="flex-1 p-4 flex justify-between">
                            <span class="text-lg font-semibold">{{
                              "landing-page-builder.voucher" | translate
                            }}</span>
                            <div
                              class="text-sm font-medium h-fit py-2 px-4 rounded-lg bg-orange-dark-500 text-white"
                            >
                              {{ "section-action.view" | translate }}
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-landing-page-footer />
    </body>
  </div>
</div>
