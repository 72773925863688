<span *ngIf="isShowButton('HEADING')" class="ql-formats">
  <select class="ql-header" aria-label="Select header">
    <option selected></option>
    <option value="1">Heading 1</option>
    <option value="2">Heading 2</option>
    <option value="3">Heading 3</option>
  </select>
</span>
<span *ngIf="isShowButton('TEXT_DECORATION')" class="ql-formats">
  <button type="button" class="ql-bold" aria-label="Bold"></button>
  <button type="button" class="ql-italic" aria-label="Italic"></button>
  <button type="button" class="ql-underline" aria-label="Underline"></button>
</span>
<span *ngIf="isShowButton('INSERT')" class="ql-formats">
  <button type="button" class="ql-link" aria-label="Insert Link"></button>
  <button type="button" class="ql-image" aria-label="Insert Image"></button>
</span>
<span *ngIf="isShowButton('LIST')" class="ql-formats">
  <button
    value="ordered"
    type="button"
    class="ql-list"
    aria-label="Ordered List"
  ></button>
  <button
    value="bullet"
    aria-label="Unordered List"
    type="button"
    class="ql-list"
  ></button>
</span>
<span *ngIf="isShowButton('ALIGNMENT')" class="ql-formats">
  <button type="button" class="ql-align" value="" aria-label="Align Left"></button>
  <button type="button" class="ql-align" value="center" aria-label="Align Center"></button>
  <button type="button" class="ql-align" value="right" aria-label="Align Right"></button>
  <button type="button" class="ql-align" value="justify" aria-label="Justify"></button>
</span>
