import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  ModuleDataDTO,
  ModuleSelectionDTO as ModuleSelection,
} from '@soctrip/angular-marketing-hub-service';
import { debounceTime, Subject } from 'rxjs';
import { STARS } from 'src/app/core/constants/marketing-builder';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { PRODUCT_LIST_FILTER } from 'src/app/core/enum/marketing-builder';
import { Catalog } from 'src/app/core/models/interfaces/catalog';
import {
  Block,
  BlockFilter,
} from 'src/app/core/models/interfaces/marketing-builder';
import { MarketingHubService } from 'src/app/core/services/marketing-hub.service';

@Component({
  selector: 'app-filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.scss'],
})
export class FilterItemComponent implements OnInit, OnChanges {
  readonly PRODUCT_LIST_FILTER = PRODUCT_LIST_FILTER;
  readonly builderPrefix = 'landing-page-builder.';
  readonly SoctripIcons = SoctripIcons;
  readonly STARS = STARS;

  @Input() filter: BlockFilter;
  @Input() filtersBy: ModuleSelection[] = [];
  @Input() blockData: Block;
  @Input() moduleData?: ModuleDataDTO = undefined;
  @Input() isInValid: boolean;


  @Output() changeData = new EventEmitter();
  @Output() isInValidChange = new EventEmitter();

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.isModal = false;
  }

  page: number = 0;
  cities: any[] = [];
  isLoading: boolean = false;
  isModal: boolean = false;
  data: any[] = [];
  totalElement: number = 0;
  keyword: string = '';
  searchString: string = '';
  searchSubject = new Subject<string>();
  isToInvalid: boolean = false;

  get moduleDataId() {
    return this.filtersBy.find((item) => item.code === this.filter?.code)?.id;
  }

  get filterData() {
    return {
      ...this.filter,
      ...this.filtersBy.find((filter) => filter.code === this.filter?.code),
    };
  }

  constructor(private marketingHubService: MarketingHubService) {}

  ngOnInit(): void {
    this.searchSubject.pipe(debounceTime(300)).subscribe((searchString) => {
      this.searchString = searchString;
      this.page = 0;
      this.fetchDataOfSelection();
    });

    this.fetchDataOfSelection();
  }

  ngOnChanges(): void {
    this.keyword = '';
  }

  fetchDataOfSelection(isLazy?: boolean) {
    if (
      this.moduleDataId &&
      this.filterData?.url &&
      this.filterData.selection_display_type !== 'AGENCY'
    ) {
      const tableFilterKeywords = this.filterData.table_filter_keywords;

      this.isLoading = true;
      this.marketingHubService
        .getSelectionsById(
          this.moduleDataId,
          this.page,
          tableFilterKeywords && this.searchString
            ? encodeURIComponent(`${tableFilterKeywords}${this.searchString}`)
            : undefined,
        )
        .subscribe({
          next: (res: any) => {
            let data: any[] = [];
            if (Array.isArray(res?.data)) {
              data = res.data;
              this.totalElement = res?.totalElement || 0;
            } else if (Array.isArray(res?.data?.data)) {
              data = res.data.data;
              this.totalElement = res.data?.totalElement || 0;
            } else {
              data = res.data.data?.data;
              this.totalElement = res.data.data?.totalElement || 0;
            }

            if (data && Array.isArray(data)) {
              if (
                this.filterData.selection_display_type ===
                PRODUCT_LIST_FILTER.CATEGORY_TREE
              ) {
                this.data = [[...data]];
              } else {
                if (isLazy) {
                  if (
                    data.every(
                      (item) => !this.data.map((x) => x.id).includes(item.id),
                    )
                  ) {
                    this.data = [...this.data, ...data];
                  }
                } else {
                  this.data = [...data];
                }
              }

              if (this.filterData.selection_choice_type === 'RADIO') {
                const filterColumn = this.filterData.filter_column || '';

                const data = this.data.find(
                  (item) =>
                    item[filterColumn] === this.filter.value?.[filterColumn],
                );
                if (data) {
                  this.filter.value = data;
                }
              }
            } else {
              this.data = [];
            }

            this.isLoading = false;
          },
          error: () => {
            this.isLoading = false;
          },
        });
    }
  }

  onLazyLoad() {
    if (this.isLoading) return;
    this.page++;
    this.fetchDataOfSelection(true);
  }

  onToggle() {
    this.isModal = !this.isModal;
  }

  onDeleteItem(data?: any) {
    switch (this.filterData.selection_display_type) {
      case PRODUCT_LIST_FILTER.RATING: {
        this.filter.value = undefined;
        break;
      }

      case PRODUCT_LIST_FILTER.RANGE: {
        this.filter.value = {
          from: undefined,
          to: undefined,
        };
        break;
      }

      default: {
        if (this.filterData.selection_choice_type === 'CHECKBOX') {
          this.filter.value = [
            ...this.filter.value.filter((item: any) => {
              const filterColumn =
                (this.filter?.filter_column?.split(',')?.length || 0) > 1
                  ? this.filter?.filter_column?.split(',')?.[0]
                  : this.filter.filter_column;

              const itemValue = filterColumn ? item?.[filterColumn] : item;

              const dataValue = filterColumn ? data?.[filterColumn] : data;

              return itemValue !== dataValue;
            }),
          ];
        } else if (this.filterData.selection_choice_type === 'RADIO') {
          this.filter.value = undefined;
        }
      }
    }
  }

  onAddKeyword(event: Event) {
    const target = event.target as HTMLInputElement;

    if (!target.value) return;

    if (this.filter.value) {
      this.filter.value.push(target.value);
    } else {
      this.filter.value = [target.value];
    }
    this.keyword = '';
  }

  // Category
  isCategoryAdded(id: string) {
    return this.filter.value?.some((category: Catalog) => category.id === id);
  }

  hoverCategory(event: MouseEvent, category: Catalog) {
    event.stopPropagation();
    this.data.splice(category.depth + 1);

    if (category.sub_catalogs) this.data.push(category.sub_catalogs);
  }

  selectCategory(event: MouseEvent, category: Catalog) {
    if (this.isCategoryAdded(category.id)) {
      return;
    }
    event.stopPropagation();

    this.filter.value?.push(category);

    this.isModal = false;
  }

  getFilterName(code?: string) {
    return this.filtersBy.find((item) => item.code === code)?.name;
  }

  get isRequired() {
    const { code } = this.filterData;
    const { required: moduleRequired } = this.moduleData || {};

    const mainSelection = this.filtersBy.find((f) => {
      return f?.required?.some((r) => r.selection_code === code);
    });

    const requiredOfSelection = mainSelection?.required?.find(
      (item) => item.selection_code === code,
    );

    const valueOfRequired = this.blockData.filters.find(
      (filter) => filter.code === mainSelection?.code,
    )?.value;

    const selectionField = requiredOfSelection?.selection_field || '';
    const selectionValue = requiredOfSelection?.selection_value;

    const isRequiredOfModule = moduleRequired?.some(
      (item) => item.selection_code === code,
    );
    const isRequiredOfSelection = Array.isArray(valueOfRequired)
      ? valueOfRequired?.some(
          (item) =>
            item[selectionField] && item[selectionField] === selectionValue,
        )
      : valueOfRequired?.[selectionField] &&
        valueOfRequired?.[selectionField] === selectionValue;

    return (
      isRequiredOfModule ||
      isRequiredOfSelection ||
      (valueOfRequired && !selectionField && !selectionValue)
    );
  }

  onDeleteSelection() {
    this.blockData.filters = [...this.blockData.filters].filter(
      (item) => item.code !== this.filter.code,
    );
    this.isInValidChange.emit(false);
  }

  onFromChange(value: number) {
    this.filter.value.from = value;
    this.validateRange();
  }

  onToChange(value: number) {
    this.filter.value.to = value;
    this.validateRange();
  }

  validateRange() {
    const from = this.filter.value.from;
    const to = this.filter.value.to;

    if (from != null && to != null && to <= from) {
      this.isToInvalid = true;
    } else {
      this.isToInvalid = false;
    }
    this.isInValidChange.emit(this.isToInvalid)
  }
}
