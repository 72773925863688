import { Component, Input } from '@angular/core';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { MODULE_ENUM } from 'src/app/core/enum/marketing-builder';
import { FileService } from 'src/app/core/services/file.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent {
  readonly SoctripIcons = SoctripIcons;
  readonly MODULE_ENUM = MODULE_ENUM;
  @Input() module = 'SHOP';
  @Input() product: any;

  thumbnail = '';

  constructor(private fileService: FileService) {}

  get defaultData() {
    switch (this.module) {
      case this.MODULE_ENUM.HOTEL: {
        return { image: '/assets/imgs/default/hotel.jpg', title: 'Hotel name' };
      }
      case this.MODULE_ENUM.FLIGHT: {
        return {
          image: '/assets/imgs/default/flight.jpg',
          title: 'Destination ',
        };
      }
      case this.MODULE_ENUM.TRAVEL_TOUR: {
        return { image: '/assets/imgs/default/tour.jpg', title: 'Tour name' };
      }
      case this.MODULE_ENUM.CAR: {
        return { image: '/assets/imgs/default/car.jpg', title: 'Car name' };
      }
      case this.MODULE_ENUM.SHOP: {
        return {
          image: '/assets/imgs/default/product.jpg',
          title: 'Product name',
        };
      }
      case this.MODULE_ENUM.RESTAURANT: {
        return {
          image: '/assets/imgs/default/product.jpg',
          title: 'Restaurant name',
        };
      }
      default: {
        return {
          image: '/src/assets/imgs/default/image.webp',
          title: 'Product name',
        };
      }
    }
  }

  getImage(id?: string) {
    if (!id) return (this.thumbnail = this.fileService.getDefaultImg());

    if (id.includes('https://')) {
      return (this.thumbnail = id);
    }
    return (this.thumbnail = this.fileService.getImgWebp(id));
  }
}
