import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MarketingHubService {
  constructor(private httpClient: HttpClient) {}

  getModuleData(
    module: string,
    filters: string,
    pageNum?: number,
    pageSize?: number,
    sort?: string,
    language?: string,
  ) {
    return this.httpClient
      .get<any>(
        `${environment.BE_URL}marketing-hub/module-data/search/${module}?${
          filters ? `filters=${filters || ''}&` : ''
        }pageNum=${pageNum || 0}&pageSize=${pageSize || 10}${
          sort ? `&sort=${sort || ''}` : ''
        }${language ? `&language=${language}` : ''}`,
      )
      .pipe(
        map((res) => {
          return {
            ...res,
            data: {
              ...res.data,
              data: {
                data: Array.isArray(res.data?.data)
                  ? res.data?.data
                  : res?.data?.data?.data,
                totalElement: Array.isArray(res.data?.data)
                  ? res?.data?.data?.total_element || res.data?.data?.length
                  : res?.data?.data?.total_element ||
                    res?.data?.data?.totalElement,
              },
            },
          };
        }),
      );
  }

  getSelectionsById(id: string, page: number, filters?: string) {
    let queryParams = new HttpParams();

    if (filters) {
      queryParams = queryParams.append('filters', filters);
    }

    return this.httpClient.get(
      `${environment.BE_URL}marketing-hub/module-selections/${id}/data${
        filters ? `?filters=${filters}` : ''
      }${(filters ? '&' : '?') + `pageNum=${page}&pageSize=10`}`,
    );
  }
}
