<div>
  <!-- DISPLAY -->
  <div class="px-5 pb-5 border-b">
    <h2 class="py-4 font-semibold text-sm">
      {{ builderPrefix + "display" | translate }}
    </h2>

    <div class="flex flex-col gap-3">
      <!-- Line -->
      <div class="flex items-center justify-between">
        <span class="text-gray-500 text-sm h-9 leading-9 pr-3">{{
          builderPrefix + "line" | translate
        }}</span>
        <p-inputNumber
          class="h-9"
          [min]="1"
          [max]="5"
          [(ngModel)]="blockData.line"
          (onInput)="onLineChange($event)"
        />
      </div>

      <!-- Items per line -->
      <div class="flex items-center justify-between">
        <span class="text-gray-500 text-sm h-9 leading-9 pr-3">
          {{ builderPrefix + "items-per-line" | translate }}
        </span>
        <p-inputNumber
          class="h-9"
          [min]="1"
          [max]="5"
          [(ngModel)]="blockData.itemPerLine"
          (onInput)="onItemPerLineChange($event)"
        />
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <!-- FILTER PRODUCTS -->
    <div class="px-5 pb-5 border-b">
      <h2 class="py-4 font-semibold text-sm">
        {{ builderPrefix + "filter-products" | translate }}
      </h2>

      <div class="flex flex-col gap-4">
        <!-- Module -->
        <p-dropdown
          styleClass="h-9 w-full"
          [options]="modulesShow"
          optionLabel="name"
          optionValue="code"
          [(ngModel)]="blockData.module"
          (onChange)="onChangeModule()"
        ></p-dropdown>

        <!-- Filter -->
        <div class="rounded-lg h-9 flex items-center p-[2px] bg-gray-100">
          <div
            *ngFor="let tab of filterTab"
            (click)="onFilterTypeChange(tab.value)"
            [ngClass]="
              (blockData.isSpecificProduct &&
                tab.value === filterTabEnum.SPECIFIC_PRODUCTS) ||
              (!blockData.isSpecificProduct &&
                tab.value === filterTabEnum.FILTERS)
                ? 'bg-white font-semibold'
                : 'font-medium text-gray-500'
            "
            class="flex-1 h-8 flex items-center justify-center rounded-md text-sm cursor-pointer"
          >
            {{ builderPrefix + tab.name | translate | sentenceCase }}
          </div>
        </div>

        <!-- Estimated product found -->
        <div class="px-4 py-2 flex flex-col gap-1 rounded-lg bg-primary-50">
          <span class="text-gray-500 text-sm">
            {{ builderPrefix + "estimated-product-found" | translate }}</span
          >
          <span class="text-xl font-semibold text-primary-600">{{
            (blockData.totalProducts || 0) >= LIMIT_TOTAL_PRODUCT
              ? "10000+"
              : blockData.totalProducts || 0
          }}</span>
        </div>

        <!-- FILTERS -->
        <ng-container *ngIf="!blockData.isSpecificProduct">
          <ng-container *ngFor="let filter of blockData.filters">
            <app-filter-item
              *ngIf="filter && filtersBy.length > 0"
              [filtersBy]="filtersBy"
              [filter]="filter"
              [blockData]="blockData"
              [moduleData]="moduleData"
              (changeData)="onChangeData(filter)"
              [(isInValid)]="isInValid"
            ></app-filter-item>
          </ng-container>
        </ng-container>

        <!-- SPECIFIC PRODUCTS -->
        <div
          *ngIf="blockData.isSpecificProduct"
          class="p-4 border bg-gray-50 rounded-lg flex flex-col gap-3"
        >
          <div class="flex items-center">
            <span class="flex-1 text-xs font-medium">
              {{
                builderPrefix + "product-list-filters-by.specific-product"
                  | translate
                  | uppercase
              }}
            </span>
          </div>
          <div>
            <div
              class="button"
              [ngClass]="isModalProduct ? 'bg-gray-600/10' : 'bg-white'"
              (click)="isModalProduct = !isModalProduct"
            >
              {{ builderPrefix + "select-product" | translate }}
            </div>

            <app-add-product-modal
              [module]="blockData.module"
              [(selectedProducts)]="blockData.specific_products"
              [(isModal)]="isModalProduct"
            >
            </app-add-product-modal>
          </div>

          <div
            *ngIf="blockData.specific_products.length"
            class="flex flex-wrap gap-2"
          >
            <div
              *ngFor="let product of blockData.specific_products; let i = index"
              class="h-7 rounded-full flex items-center gap-1 bg-gray-200 p-1 pr-2 w-fit max-w-full"
            >
              <div class="w-5 min-w-[20px] h-5">
                <app-custom-image
                  styleClass="w-full h-full rounded-full overflow-hidden"
                  [imgId]="getDataAgency(product).avatar"
                  [alt]="getDataAgency(product).name"
                ></app-custom-image>
              </div>
              <span class="text-sm font-medium truncate">
                {{ getDataAgency(product).name }}</span
              >
              <i
                class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
                (click)="onDeleteProduct(product.id)"
              ></i>
            </div>
          </div>
        </div>

        <!-- Count and add filter -->
        <div class="flex items-center">
          <!-- <div class="flex-1 button" (click)="onCountProduct()">
            Count product
          </div> -->
          <div class="flex-1">
            <app-button
              [loading]="isCounting"
              styleClass="w-full"
              variant="outline-secondary"
              [label]="builderPrefix + 'count-product' | translate"
              (onClick)="onCountProduct()"
              [disabled]="isInValid"
            ></app-button>
          </div>
          <div
            *ngIf="!blockData.isSpecificProduct"
            (click)="onToggleFilter()"
            class="flex-1 button ml-2"
            [ngClass]="isModalFilter ? 'bg-gray-600/10' : 'bg-white'"
          >
            {{ builderPrefix + "add-filter" | translate }}
          </div>

          <p-overlay [(visible)]="isModalFilter" appendTo="body">
            <ul
              class="mt-1 p-1 bg-white w-[284px] rounded-lg max-h-[400px] overflow-y-auto border shadow-md"
            >
              <li
                *ngFor="let filter of filtersBy"
                [ngClass]="
                  isDisableFilter(filter.code)
                    ? 'opacity-50'
                    : 'hover:bg-gray-100 cursor-pointer'
                "
                class="p-3 text-sm font-medium transition rounded"
                (click)="onAddFilter(filter)"
              >
                {{ filter.name }}
              </li>
            </ul>
          </p-overlay>
        </div>
      </div>
    </div>

    <!-- SORT PRODUCTS -->
    <div class="px-5 pb-5">
      <h2 class="py-4 font-semibold text-sm">
        {{ builderPrefix + "sort-products.sort-products" | translate }}
      </h2>

      <div
        class="flex flex-col gap-3"
        [ngClass]="{
          'pb-4': blockData.sorts.length > 0
        }"
      >
        <div
          *ngFor="let sort of blockData.sorts; let i = index"
          class="flex items-center gap-2"
        >
          <div class="flex flex-col">
            <i
              class="{{ SoctripIcons.CHEVRON_UP }}"
              [ngClass]="i === 0 ? 'opacity-50' : 'cursor-pointer'"
              (click)="i !== 0 ? onMoveUpSort(i) : null"
            ></i>
            <i
              class="{{ SoctripIcons.CHEVRON_DOWN }}"
              [ngClass]="
                i === blockData.sorts.length - 1
                  ? 'opacity-50'
                  : 'cursor-pointer'
              "
              (click)="
                i !== blockData.sorts.length - 1 ? onMoveDownSort(i) : null
              "
            ></i>
          </div>

          <div class="flex-1 max-w-[calc(100%-48px)] flex items-center gap-2">
            <div class="w-1/2">
              <p-dropdown
                appendTo="body"
                [options]="sortsBy"
                [(ngModel)]="sort.key"
                optionValue="value"
                optionLabel="name"
                styleClass="w-full h-9"
                optionDisabled="disabled"
              >
                <ng-template let-item pTemplate="item">
                  <span>
                    {{ item.name }}
                  </span>
                </ng-template>
                <ng-template let-selectedItem pTemplate="selectedItem">
                  <span>
                    {{ selectedItem.name }}
                  </span>
                </ng-template>
              </p-dropdown>
            </div>

            <div class="w-1/2">
              <p-dropdown
                appendTo="body"
                [options]="getSortValueByKey(sort.key)"
                [(ngModel)]="sort.value"
                styleClass="w-full h-9"
                optionValue="value"
                optionLabel="name"
              >
                <ng-template let-item pTemplate="item">
                  <span>
                    {{
                      builderPrefix +
                        "sort-products." +
                        item.name.replaceAll(" ", "-")
                        | lowercase
                        | translate
                    }}
                  </span>
                </ng-template>
                <ng-template let-selectedItem pTemplate="selectedItem">
                  <span>
                    {{
                      builderPrefix +
                        "sort-products." +
                        selectedItem.name.replaceAll(" ", "-")
                        | lowercase
                        | translate
                    }}
                  </span>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <i
            class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
            (click)="onDeleteSort(sort.key)"
          ></i>
        </div>
      </div>

      <div>
        <div
          class="button"
          [ngClass]="isModalSort ? 'bg-gray-600/10' : 'bg-white'"
          (click)="onToggleAddSort()"
        >
          {{ builderPrefix + "add-sort" | translate }}
        </div>
        <p-overlay [(visible)]="isModalSort" appendTo="body">
          <div
            class="mb-1 p-1 bg-white w-[284px] rounded-lg max-h-[400px] overflow-y-auto border shadow-md"
          >
            <ul *ngIf="sortsBy.length > 0; else noDataSort">
              <li
                *ngFor="let SORT of sortsBy"
                class="p-3 text-sm font-medium transition rounded"
                [ngClass]="
                  isDisableSort(SORT.value)
                    ? 'opacity-50'
                    : 'hover:bg-gray-100 cursor-pointer'
                "
                (click)="onAddSort(SORT.value, SORT.scope)"
              >
                {{ SORT.name }}
              </li>
            </ul>
          </div>

          <ng-template #noDataSort>
            <div class="flex items-center justify-center py-10">
              <div class="text-sm font-medium">No data</div>
            </div>
          </ng-template>
        </p-overlay>
      </div>
    </div>
  </ng-container>

  <ng-template #loadingTemplate>
    <div class="flex items-center justify-center py-40">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </ng-template>
</div>
